import '@app/shared/extensions/form-array.extension';

import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';
import {Cest} from '@app/abstraction/domain/entities/fiscal/cest/cest.entity';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {Familia} from '@app/abstraction/domain/entities/produtos/familia/familia.entity';
import {Grupo} from '@app/abstraction/domain/entities/produtos/grupo/grupo.entity';
import {Marca} from '@app/abstraction/domain/entities/produtos/marca/marca.entity';
import {ProdutoVariacaoCor} from '@app/abstraction/domain/entities/produtos/produto-variacao-cor/produto-variacao-cor.entity';
import {ProdutoVariacaoReferencia} from '@app/abstraction/domain/entities/produtos/produto-variacao-referencia/produto-variacao-referencia.entity';
import {ProdutoVariacaoTamanho} from '@app/abstraction/domain/entities/produtos/produto-variacao-tamanho/produto-variacao-tamanho.entity';
import {ProdutoVariacaoDimensionamento} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-dimensionamento.entity';
import {ProdutoVariacaoEstoque} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-estoque.entity';
import {ProdutoVariacaoFoto} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-foto.entity';
import {ProdutoVariacaoPreco} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-preco.entity';
import {ProdutoVariacao} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao.entity';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {Secao} from '@app/abstraction/domain/entities/produtos/secao/secao.entity';
import {Subgrupo} from '@app/abstraction/domain/entities/produtos/subgrupo/subgrupo.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {AreaEstoqueFacade} from '@app/abstraction/domain/facades/estoque/area-estoque/area-estoque.facade';
import {NcmFacade} from '@app/abstraction/domain/facades/fiscal/ncm/ncm.facade';
import {FamiliaFacade} from '@app/abstraction/domain/facades/produtos/familia/familia.facade';
import {GrupoFacade} from '@app/abstraction/domain/facades/produtos/grupo/grupo.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {SecaoFacade} from '@app/abstraction/domain/facades/produtos/secao/secao.facade';
import {SubgrupoFacade} from '@app/abstraction/domain/facades/produtos/subgrupo/subgrupo.facade';
import {UnidadeComercialFacade} from '@app/abstraction/domain/facades/produtos/unidade-comercial/unidade-comercial.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {FornecedorProdutoForm, IFornecedorProdutoForm,} from '@app/abstraction/domain/forms/cadastros/fornecedor-produto.form';
import {EmpresaListagem} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-listagem.interface';
import {FornecedorListagem} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-listagem.interface';
import {AreaEstoqueListagem} from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-listagem.interface';
import {MovimentacaoListagem} from '@app/abstraction/domain/interfaces/estoque/movimentacao/movimentacao-listagem.interface';
import {NcmListagem} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-listagem.interface';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {ProdutoTipo} from '@app/shared/enums/produto-tipo.enum';
import {ProdutoVendidoPor} from '@app/shared/enums/produto-vendido-por.enum';
import {RegimeTributario} from '@app/shared/enums/regimes-tributarios.enum';
import {FileService} from '@app/shared/services/file/file.service';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';
import {Guid} from 'guid-typescript';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Subscription} from 'rxjs';

import {IProdutoFoodForm, ProdutoFoodForm} from './produto-food.form';
import {IProdutoTributacaoForm, ProdutoTributacaoForm,} from './produto-tributacao.form';
import {IProdutoVariacaoDimensionamentoForm} from './produto-variacao-dimensionamento.form';
import {IProdutoVariacaoEstoqueForm, ProdutoVariacaoEstoqueForm,} from './produto-variacao-estoque.form';
import {ProdutoVariacaoFotoForm} from './produto-variacao-foto.form';
import {IProdutoVariacaoPrecoForm, ProdutoVariacaoPrecoForm,} from './produto-variacao-preco.form';
import {IProdutoVariacaoForm, ProdutoVariacaoForm,} from './produto-variacao.form';

export interface IProdutoForm {
  ativarVasilhame: FormControl<boolean>;
  ativo: FormControl<boolean>;
  bloquearVenda: FormControl<boolean>;
  composicaoItem: FormControl<boolean>;
  decomposicaoEntradaNotaFiscal: FormControl<boolean>;
  descricao: FormControl<string>;
  descricaoReduzida: FormControl<string>;
  etiquetaGerar: FormControl<boolean>;
  familiaId: FormControl<string>;
  grupoId: FormControl<string>;
  habilitarNoEcommerce: FormControl<boolean>;
  id: FormControl<string>;
  movimentarEstoque: FormControl<boolean>;
  ncmCodigo: FormControl<string>;
  ncmExcecao: FormControl<string|number>;
  cestCodigo: FormControl<string>;
  pedirDocumentoVendaPdv: FormControl<boolean>;
  permitirAlterarPrecoPdv: FormControl<boolean>;
  permitirComissao: FormControl<boolean>;
  permitirCotacao: FormControl<boolean>;
  permitirDesconto: FormControl<boolean>;
  permitirDescricaoComplementar: FormControl<boolean>;
  permitirProdutoAssociado: FormControl<boolean>;
  permitirTabelaNutricional: FormControl<boolean>;
  produtoIdAssociado: FormControl<string>;
  produtoPesado: FormControl<boolean>;
  produtoPesadoTipo: FormControl<string>;
  produtoTipo: FormControl<ProdutoTipo>;
  produtoVariacaoSimples: FormGroup<IProdutoVariacaoForm>;
  produtoVendidoPor: FormControl<ProdutoVendidoPor>;
  quantidadeEntrada: FormControl<number>;
  quantidadeSaida: FormControl<number>;
  quantidadeSaidaProdutoAssociado: FormControl<number>;
  secaoId: FormControl<string>;
  marcaId: FormControl<string>;
  modelo: FormControl<string>;
  servico: FormControl<boolean>;
  subGrupoId: FormControl<string>;
  tabelaNutricionalId: FormControl<string>;
  unidadeComercialEntrada: FormControl<string>;
  unidadeComercialSaida: FormControl<string>;
  variacoes: FormArray<FormGroup<IProdutoVariacaoForm>>;
  codigoSistema: FormControl<string>;
  codigoRapido: FormControl<string>;
  tributacao?: FormGroup<IProdutoTributacaoForm>;
  food?: FormGroup<IProdutoFoodForm>;
  diasDeValidade: FormControl<number>;
  createdOn: FormControl<Date>;
  lastModifiedOn: FormControl<Date>;
  setores: FormControl<string[]>;
}

export class ProdutoForm extends BaseForm {
  form: FormGroup<IProdutoForm>;
  operacaoForm: OperacaoForm;
  mostrarGeradorEanModal: boolean;
  mostrarNcmBuscaRapidaModal: boolean;
  variacaoForm: FormGroup<IProdutoVariacaoForm>;
  precoForm: FormGroup<IProdutoVariacaoPrecoForm>;
  estoqueForm: FormGroup<IProdutoVariacaoEstoqueForm>;
  fornecedorProdutoForm: FormGroup<IFornecedorProdutoForm>;
  mostrarFamiliaCadastroRapidoModal: boolean;
  mostrarMarcaCadastroRapidoModal: boolean;
  mostrarGrupoCadastroRapidoModal: boolean;
  mostrarCestBuscaRapidaModal: boolean;
  mostrarProdutoBuscaRapidaModal: boolean;
  mostrarProdutoVariacaoCorCadastroRapidoModal: boolean;
  mostrarEstoqueModal: boolean;
  mostrarVariacaoModal: boolean;
  mostrarPrecoModal: boolean;
  mostrarProdutoVariacaoReferenciaCadastroRapidoModal: boolean;
  mostrarProdutoVariacaoTamanhoCadastroRapidoModal: boolean;
  mostrarSecaoCadastroRapidoModal: boolean;
  mostrarSubgrupoCadastroRapidoModal: boolean;
  mostrarTabelaNutricionalCadastroRapidoModal: boolean;
  mostrarUnidadeComercialCadastroRapidoModal: boolean;
  mostrarNaturezaReceitaCadastroRapidoModal: boolean;
  mostrarSetorCadastroRapidoModal: boolean;
  mostrarFornecedorProdutoModal: boolean;
  operacaoFormProdutoVariacao: OperacaoForm;
  operacaoFormEstoque: OperacaoForm;
  operacaoFormProdutoVariacaoPreco: OperacaoForm;
  variacaoEditada: {index: number};
  estoqueEditado: {index: number; areaEstoqueId: string};
  variacaoEstoqueModalTitulo: string;
  produtoVariacaoModalTitulo: string;
  precoEditado: {index: number};
  produtoVariacaoPrecoModalTitulo: string;
  unidadesComerciaisSubscription: Subscription;
  secoesSubscription: Subscription;
  gruposSubscription: Subscription;
  subgruposSubscription: Subscription;
  familiasSubscription: Subscription;
  listaEanGerados: string[];
  dadosTabelaVariacoesCodigosBarras: any[] = [];
  operacaoFormFornecedorProduto: OperacaoForm;
  fornecedorProdutoEditado: {index: number};
  fornecedorProdutoModalTitulo: string;
  mostrarFornecedorBuscaRapidaModal: boolean;
  eanRelacionado: string;
  tamanhos: string[] = [];
  cores: string[] = [];
  referencias: string[] = [];
  precoVenda: number;
  informacaoAdicional: string = '';
  movimentacoes: MovimentacaoListagem[];
  dimensionamento: ProdutoVariacaoDimensionamento =
      new ProdutoVariacaoDimensionamento();
  empresaPadraoDropdown: any = {
    id: Guid.create().toString(),
    nomeFantasia: 'TODAS',
    nomeRazaoSocial: 'TODAS',
  };
  areaEstoquePadraoDropdown: any = {
    id: Guid.create().toString(),
    descricao: 'TODAS',
  };
  get titulo() {
    switch (this.operacaoForm) {
      case OperacaoForm.Adicionar:
        return 'Novo produto';
      case OperacaoForm.Editar:
        return 'Editar produto';
      case OperacaoForm.Duplicar:
        return 'Duplicar produto';
    }
  }
  get labelBreadcrumb() {
    switch (this.operacaoForm) {
      case OperacaoForm.Adicionar:
        return 'Adicionar';
      case OperacaoForm.Editar:
        return 'Editar';
      case OperacaoForm.Duplicar:
        return 'Duplicar';
    }
  }
  get mensagemSucessoAoSalvar() {
    switch (this.operacaoForm) {
      case OperacaoForm.Adicionar:
        return 'Produto adicionado com sucesso!';
      case OperacaoForm.Editar:
        return 'Produto editado com sucesso!';
      case OperacaoForm.Duplicar:
        return 'Produto duplicado com sucesso!';
    }
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get operacaoFormEditar() {
    return this.operacaoForm === OperacaoForm.Editar;
  }
  get operacaoFormDuplicar() {
    return this.operacaoForm === OperacaoForm.Duplicar;
  }
  get estoqueIdForm() {
    return this.estoqueForm.get('id').value;
  }
  get empresaUnica() {
    return this.authFacade.empresaUnica;
  }
  get produtoPesado() {
    return this.form.controls.produtoPesado.value as boolean;
  }
  get produtoTipoSimples() {
    return (
        !this.form.controls.produtoTipo.value ||
        this.form.controls.produtoTipo.value === ProdutoTipo.Simples);
  }
  get produtoTipoVariacao() {
    return this.form.controls.produtoTipo.value === ProdutoTipo.Variacao;
  }
  get variacoes() {
    return this.form.controls.variacoes.value.map((p: any) => {
      if (p.precos.length) p.valorVenda = p.precos[0].valorVenda1;
      return p;
    }) as ProdutoVariacao[];
  }
  get produtoVariacaoPreco() {
    const precos =
        this.variacaoForm.controls.precos.value as ProdutoVariacaoPreco[];
    return precos.filter(
        (p: ProdutoVariacaoPreco) => this.authFacade.grupoLogado.empresas.some(
            (e) => e.id === p.empresaId));
  }
  get produtoVariacaoEstoque() {
    const estoques =
        this.variacaoForm.controls.estoques.value as ProdutoVariacaoEstoque[];

    return estoques.filter(
        (e: ProdutoVariacaoEstoque) => this.areaEstoqueFacade.areasEstoque.some(
            (a) => a.id === e.areaEstoqueId));
  }
  get produtoVariacaoFoto() {
    return this.variacaoForm.controls.fotos.value as ProdutoVariacaoFoto[];
  }
  get produtoVariacaoFornecedor() {
    return this.variacaoForm.controls.fornecedores.value as FornecedorProduto[];
  }
  get produtoVariacaoDimensionamento() {
    return this.variacaoForm.controls.dimensionamento as
        FormGroup<IProdutoVariacaoDimensionamentoForm>;
  }
  get produtoVariacaoFormArray() {
    return this.form.controls.variacoes as
        FormArray<FormGroup<IProdutoVariacaoForm>>;
  }
  get produtoVariacaoSimplesFormGroup() {
    return this.form.controls.produtoVariacaoSimples as
        FormGroup<IProdutoVariacaoForm>;
  }
  get produtoVariacaoSimplesPreco() {
    const precos =
        this.form.controls.produtoVariacaoSimples.controls.precos.value as
        ProdutoVariacaoPreco[];

    return precos.filter(
        (p: ProdutoVariacaoPreco) => this.authFacade.grupoLogado.empresas.some(
            (e) => e.id === p.empresaId));
  }
  get produtoVariacaoSimplesFornecedores() {
    const fornecedores =
        this.form.controls.produtoVariacaoSimples.controls.fornecedores.value as
        FornecedorProduto[];

    return fornecedores;
  }
  get produtoVariacaoSimplesDimensionamentoForm() {
    const dimensionamento =
        this.form.controls.produtoVariacaoSimples.controls.dimensionamento as
        FormGroup<IProdutoVariacaoDimensionamentoForm>;

    return dimensionamento;
  }
  get produtoVariacaoSimplesEstoque() {
    const estoques =
        this.form.controls.produtoVariacaoSimples.controls.estoques.value as
        ProdutoVariacaoEstoque[];

    return estoques.filter(
        (e: ProdutoVariacaoEstoque) => this.areaEstoqueFacade.areasEstoque.some(
            (a) => a.id === e.areaEstoqueId));
  }
  get produtoPorPeso() {
    return (
        this.form.controls.produtoVendidoPor?.value == ProdutoVendidoPor.Peso);
  }
  get produtoVariacaoSimplesFoto() {
    return this.form.controls.produtoVariacaoSimples.controls.fotos.value as
        ProdutoVariacaoFoto[];
  }
  get permitirTabelaNutricional() {
    return this.form.controls.permitirTabelaNutricional.value;
  }
  get permitirProdutoAssociado() {
    return this.form.controls.permitirProdutoAssociado.value;
  }
  get ncmCodigo() {
    return this.form?.controls?.ncmCodigo?.value as string;
  }
  get empresas() {
    return <EmpresaListagem[]>[
      this.empresaPadraoDropdown,
      ...this.authFacade.grupoLogado.empresas,
    ];
  }
  get areasEstoque() {
    const areasEstoque = this.areaEstoqueFacade.areasEstoque;
    if (areasEstoque.length > 1) {
      return <AreaEstoqueListagem[]>[
        this.areaEstoquePadraoDropdown,
        ...areasEstoque,
      ];
    }
    return areasEstoque;
  }
  get tributacaoForm() {
    return this.form.controls.tributacao;
  }
  get eanRelacionados() {
    const form = this.buscarFormProdutoSimplesOuVariacao();
    return form.controls.eanRelacionados.value as [];
  }
  get mostrarDataCriacao() {
    return this.form.controls?.createdOn?.value;
  }
  get mostrarDataAlteracao() {
    return this.form.controls?.lastModifiedOn?.value;
  }

  constructor(
      private loadingService: NgxUiLoaderService,
      private areaEstoqueFacade: AreaEstoqueFacade,
      private authFacade: AuthFacade, private empresaFacade: EmpresaFacade,
      private errorMessagesFacade: ErrorMessageFacade,
      private grupoFacade: GrupoFacade, private ncmFacade: NcmFacade,
      private produtoFacade: ProdutoFacade, private secaoFacade: SecaoFacade,
      private subgrupoFacade: SubgrupoFacade,
      private familiaFacade: FamiliaFacade,
      private unidadeComercialFacade: UnidadeComercialFacade,
      private fornecedorFacade: FornecedorFacade,
      private fileService: FileService, private toastrService: ToastrService) {
    super();
  }
  private async criarFormsProdutoVariacao(produto: Produto) {
    await Promise.all(produto.variacoes.map(async (variacao) => {
      this.adicionarProdutoVariacaoFormGroup(variacao);
    }));
  }
  private async completarCamposFornecedores(produto: Produto) {
    if (produto.variacoes?.length) {
      for (const variacao of produto.variacoes) {
        if (variacao.fornecedores?.length) {
          for (const fornecedorProduto of variacao.fornecedores) {
            await this.preencherCamposRestantesFornecedorProduto(
                fornecedorProduto);
          }
        }
      }
    }
  }
  private async preencherCamposRestantesFornecedorProduto(
      fornecedorProduto: FornecedorProduto) {
    const fornecedor =
        await this.fornecedorFacade.buscarPorId(fornecedorProduto.fornecedorId)
            .catch(() => {
              return this.errorMessagesFacade.mostrarMensagens(
                  'Fornecedor não encontrado.');
            });
    fornecedorProduto.fornecedorNome = fornecedor?.nomeFantasia ??
        fornecedor?.nomeRazaoSocial ?? 'Fornecedor não encontrado';
    fornecedorProduto.fornecedorDocumento = fornecedor?.documento;
  }
  private async preencherForm(produto?: Produto) {
    produto.produtoTipo = Number(produto?.produtoTipo);
    produto.ncmExcecao = produto?.ncmExcecao?.toString().padLeft('0', 2);

    if (produto.createdOn) produto.createdOn = produto.createdOn.toLocaleDate();
    if (produto.lastModifiedOn)
      produto.lastModifiedOn = produto.lastModifiedOn.toLocaleDate();

    if (produto.variacoes.length > 0) {
      this.setarVariacaoSimples(produto);
      await this.completarCamposFornecedores(produto);
      await this.criarFormsProdutoVariacao(produto);
    }

    if (!produto.tributacao && produto.ncmCodigo && produto.ncmExcecao &&
        this.authFacade?.enderecoEmpresaLogada?.uf) {
      this.ncmFacade
          .buscarPorNcmCodigo(
              produto.ncmCodigo, this.authFacade.enderecoEmpresaLogada?.uf,
              produto.ncmExcecao)
          .then((ncm: Ncm) => {
            this.preencherNcmSelecionado(ncm);
          })
          .catch(() => {
            return this.errorMessagesFacade.mostrarMensagens(
                'Erro ao buscar tributação do produto.');
          });
    }

    this.form.patchValue(produto);
  }
  private adicionarProdutoVariacaoEstoque(
      variacaoEstoque: any, areaEstoqueId: string,
      form: FormGroup<IProdutoVariacaoForm>) {
    const estoques = form.controls.estoques.value as [];

    const todasAreasEstoque =
        areaEstoqueId == this.areaEstoquePadraoDropdown.id;
    if (todasAreasEstoque) {
      this.adicionarEstoqueParaTodasAreasEstoque(estoques, variacaoEstoque);
    } else {
      this.adicionarProdutoVariacaoEstoqueFormGroup(variacaoEstoque);
    }
  }
  private adicionarEstoqueParaTodasAreasEstoque(
      estoques: ProdutoVariacaoEstoque[],
      variacaoEstoque: ProdutoVariacaoEstoque) {
    const areasEstoque = this.areaEstoqueFacade.areasEstoque;

    areasEstoque.forEach((areaEstoque) => {
      if (!estoques.some(
              (estoque: any) => estoque.areaEstoqueId === areaEstoque.id)) {
        variacaoEstoque.areaEstoqueId = areaEstoque.id;
        this.adicionarProdutoVariacaoEstoqueFormGroup(variacaoEstoque);
      }
    });
  }
  private adicionarProdutoVariacaoEstoqueFormGroup(produtoVariacaoEstoque?:
                                                       ProdutoVariacaoEstoque) {
    const form = this.variacaoForm ? this.variacaoForm :
                                     this.produtoVariacaoSimplesFormGroup;
    const produtoVariacaoEstoqueFormArray = form.controls.estoques;
    produtoVariacaoEstoqueFormArray.push(
        this.criarEstoqueFormGroup(produtoVariacaoEstoque));
  }
  private adicionarProdutoVariacaoPrecoParaTodasEmpresas(
      form: FormGroup<IProdutoVariacaoForm>,
      produtoVariacaoPreco: ProdutoVariacaoPreco) {
    this.authFacade.grupoLogado.empresas.forEach((empresa) => {
      if (!(form.controls.precos.value as [])
               .some((preco: any) => preco.empresaId === empresa.id)) {
        produtoVariacaoPreco.empresaId = empresa.id;
        this.adicionarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco);
      }
    });
  }
  private adicionarProdutoVariacaoPreco(
      produtoVariacaoPreco: ProdutoVariacaoPreco,
      form: FormGroup<IProdutoVariacaoForm>) {
    if (produtoVariacaoPreco.empresaId === this.empresaPadraoDropdown.id) {
      this.adicionarProdutoVariacaoPrecoParaTodasEmpresas(
          form, produtoVariacaoPreco);
    } else {
      this.adicionarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco);
    }
  }
  private adicionarProdutoVariacaoFormGroup(variacao?: ProdutoVariacao) {
    this.produtoVariacaoFormArray.push(this.criarVariacaoFormGroup(variacao));
  }
  private adicionarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco?:
                                                     ProdutoVariacaoPreco) {
    const form = this.variacaoForm ? this.variacaoForm :
                                     this.produtoVariacaoSimplesFormGroup;
    const produtoVariacaoPrecoFormArray = form.controls.precos;
    produtoVariacaoPrecoFormArray.push(
        this.criarPrecoFormGroup(produtoVariacaoPreco));
  }
  private adicionarProdutoVariacaoFotoFormGroup(produtoVariacaoFoto?:
                                                    ProdutoVariacaoFoto) {
    const form = this.buscarFormProdutoSimplesOuVariacao();
    const produtoVariacaoFotoFormArray = form.controls.fotos;
    produtoVariacaoFotoFormArray.push(
        this.criarFotoFormGroup(produtoVariacaoFoto));
  }
  private adicionarFornecedorProdutoFormGroup(fornecedorProduto:
                                                  FornecedorProduto) {
    const form = this.buscarFormProdutoSimplesOuVariacao();
    const fornecedorProdutoFormArray = form.controls.fornecedores;
    fornecedorProdutoFormArray.push(
        this.criarFornecedorProdutoFormGroup(fornecedorProduto));
  }
  private alterarProdutoVariacaoEstoque(
      variacaoEstoque: ProdutoVariacaoEstoque, areaEstoqueId: string,
      form: FormGroup<IProdutoVariacaoForm>) {
    const estoques = form.controls.estoques.value as ProdutoVariacaoEstoque[];

    const todasAreasEstoque =
        areaEstoqueId == this.areaEstoquePadraoDropdown.id;
    if (todasAreasEstoque) {
      this.alterarEstoqueParaTodasAreasEstoque(estoques, variacaoEstoque, form);
    } else {
      const index = estoques.findIndex(
          (e) => e.areaEstoqueId == variacaoEstoque.areaEstoqueId);
      this.alterarProdutoVariacaoEstoqueFormGroup(form, index, variacaoEstoque);
    }
  }
  private alterarEstoqueParaTodasAreasEstoque(
      estoques: any, variacaoEstoque: ProdutoVariacaoEstoque,
      form: FormGroup<IProdutoVariacaoForm>) {
    const areasEstoque = this.areaEstoqueFacade.areasEstoque;

    areasEstoque.forEach((areaEstoque) => {
      const existeEmpresaComEstoqueCadastrado = estoques.some(
          (estoque: ProdutoVariacaoEstoque) =>
              estoque.areaEstoqueId === areaEstoque.id);

      variacaoEstoque.areaEstoqueId = areaEstoque.id;

      if (existeEmpresaComEstoqueCadastrado) {
        const indexEstoque = estoques.findIndex(
            (estoque: any) => estoque.areaEstoqueId === areaEstoque.id);
        this.alterarProdutoVariacaoEstoqueFormGroup(
            form, indexEstoque, variacaoEstoque);
      } else {
        this.adicionarProdutoVariacaoEstoqueFormGroup(variacaoEstoque);
      }
    });
  }
  private alterarProdutoVariacaoPrecoParaTodasEmpresas(
      form: FormGroup<IProdutoVariacaoForm>,
      produtoVariacaoPreco: ProdutoVariacaoPreco) {
    const empresas = this.authFacade.grupoLogado.empresas;
    const precos = form.controls.precos.value as ProdutoVariacaoPreco[];

    empresas.forEach((empresa) => {
      const existeEmpresaComPrecoCadastrado = precos.some(
          (preco: ProdutoVariacaoPreco) => preco.empresaId === empresa.id);

      if (existeEmpresaComPrecoCadastrado) {
        const indexPreco =
            precos.findIndex((preco: any) => preco.empresaId === empresa.id);

        const precoFormGroup = form.controls.precos.at(indexPreco);

        precoFormGroup.controls.empresaId.setValue(empresa.id);
        precoFormGroup.controls.valorCusto.setValue(
            produtoVariacaoPreco.valorCusto);
        precoFormGroup.controls.valorVenda1.setValue(
            produtoVariacaoPreco.valorVenda1);
        precoFormGroup.controls.valorVenda2.setValue(
            produtoVariacaoPreco.valorVenda2);
        precoFormGroup.controls.valorCompra.setValue(
            produtoVariacaoPreco.valorCompra);
        precoFormGroup.controls.margem1Fixa.setValue(
            produtoVariacaoPreco.margem1Fixa);
        precoFormGroup.controls.margem1Praticada.setValue(
            produtoVariacaoPreco.margem1Praticada);
      } else {
        produtoVariacaoPreco.empresaId = empresa.id;
        this.adicionarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco);
      }
    });
  }
  private alterarProdutoVariacaoPrecoParaUmaEmpresa(
      form: FormGroup<IProdutoVariacaoForm>,
      produtoVariacaoPreco: ProdutoVariacaoPreco) {
    const index = this.precoEditado.index;
    form.controls.precos.removeAt(index);
    form.controls.precos.insert(
        index, this.criarPrecoFormGroup(produtoVariacaoPreco));
  }
  private alterarProdutoVariacaoPreco(
      produtoVariacaoPreco: ProdutoVariacaoPreco,
      form: FormGroup<IProdutoVariacaoForm>) {
    if (produtoVariacaoPreco.empresaId === this.empresaPadraoDropdown.id) {
      this.alterarProdutoVariacaoPrecoParaTodasEmpresas(
          form, produtoVariacaoPreco);
    } else {
      this.alterarProdutoVariacaoPrecoParaUmaEmpresa(
          form, produtoVariacaoPreco);
    }
  }
  private alterarFornecedorProdutoFormGroup(fornecedorProduto:
                                                FornecedorProduto) {
    const index = this.fornecedorProdutoEditado.index;
    const form = this.buscarFormProdutoSimplesOuVariacao();
    const fornecedoresProdutoFormArray = form.controls.fornecedores;
    fornecedoresProdutoFormArray.removeAt(index);
    fornecedoresProdutoFormArray.insert(
        index, this.criarFornecedorProdutoFormGroup(fornecedorProduto));
  }
  private alterarProdutoVariacaoEstoqueFormGroup(
      form: FormGroup<IProdutoVariacaoForm>, index: any,
      variacaoEstoque: ProdutoVariacaoEstoque) {
    form.controls.estoques.removeAt(index);
    form.controls.estoques.insert(
        index, this.criarEstoqueFormGroup(variacaoEstoque));
  }
  private aplicarValidacoesFornecedorProduto() {
    this.fornecedorProdutoForm.controls.fornecedorNome.addValidators(
        CustomValidators.required('Fornecedor é obrigatório'));
  }
  private criarVariacaoFormGroup(variacao?: ProdutoVariacao, index?: number) {
    if (index >= 0) {
      this.operacaoFormProdutoVariacao = OperacaoForm.Editar;
      this.variacaoEditada = {index: index};
    }
    const variacaoForm = ProdutoVariacaoForm.criarForm(variacao);

    if (variacao?.precos?.length > 0) {
      variacao.precos.forEach((preco) => {
        const variacaoPrecoForm = variacaoForm.controls.precos;

        variacaoPrecoForm.push(this.criarPrecoFormGroup(preco));
      });
    }
    if (variacao?.estoques?.length > 0) {
      variacao.estoques.forEach((estoque) => {
        const variacaoEstoqueForm = variacaoForm.controls.estoques;

        variacaoEstoqueForm.push(this.criarEstoqueFormGroup(estoque));
      });
    }
    if (variacao?.fotos?.length > 0) {
      variacao.fotos.forEach((foto) => {
        const variacaoFotoForm = variacaoForm.controls.fotos;

        variacaoFotoForm.push(this.criarFotoFormGroup(foto));
      });
    }
    if (variacao?.fornecedores?.length > 0) {
      variacao.fornecedores.forEach((fornecedor) => {
        const fornecedorProdutoForm = variacaoForm.controls.fornecedores;

        fornecedorProdutoForm.push(
            this.criarFornecedorProdutoFormGroup(fornecedor));
      });
    }
    if (variacao?.eanRelacionados?.length > 0) {
      variacao.eanRelacionados.forEach((eanRelacionado) => {
        const eanRelacionados = variacaoForm.controls.eanRelacionados;
        eanRelacionados.push(new FormControl(eanRelacionado));
      });
    }

    return variacaoForm;
  }
  private criarPrecoFormGroup(
      produtoVariacaoPreco?: ProdutoVariacaoPreco, index?: number) {
    if (index >= 0) {
      this.operacaoFormProdutoVariacaoPreco = OperacaoForm.Editar;
      this.precoEditado = {index: index};
    }
    return ProdutoVariacaoPrecoForm.criarForm(produtoVariacaoPreco);
  }
  private criarEstoqueFormGroup(
      variacaoEstoque?: ProdutoVariacaoEstoque, index?: number) {
    if (index >= 0) {
      this.operacaoFormEstoque = OperacaoForm.Editar;

      this.estoqueEditado = {
        index: index,
        areaEstoqueId: variacaoEstoque.areaEstoqueId,
      };
    }
    const areaEstoqueId = this.areasEstoque.length > 1 ?
        this.areaEstoquePadraoDropdown.id :
        this.areasEstoque?.first()?.id;
    return ProdutoVariacaoEstoqueForm.criarForm(variacaoEstoque, areaEstoqueId);
  }
  private criarFotoFormGroup(variacaoFoto?: ProdutoVariacaoFoto) {
    return ProdutoVariacaoFotoForm.criarForm(variacaoFoto);
  }
  private calcularValorVenda(margem = 0, valorCusto = 0) {
    if (margem >= 0 && valorCusto >= 0) {
      let valorVenda = valorCusto + valorCusto * (margem / 100);
      valorVenda = Number.isNaN(valorVenda) ? 0 : valorVenda;
      this.precoForm.controls.valorVenda1.setValue(valorVenda);
    }
  }
  // private calcularMargemFixa(valorVenda: number = 0, valorCusto: number =
  // 0) {
  //   if (valorVenda >= 0.0 && valorCusto >= 0.0) {
  //     let margemFixa = ((valorVenda - valorCusto ?? 0) / valorCusto ?? 0) *
  //     100; margemFixa = Number.isNaN(margemFixa) ||
  //     !Number.isFinite(margemFixa) ?
  //         0 :
  //         margemFixa;
  //     this.precoForm.controls.margem1Fixa.setValue(margemFixa);
  //   }
  // }
  private calcularMargemPraticada(
      valorVenda: number = 0, valorCusto: number = 0) {
    if (valorVenda >= 0.0 && valorCusto >= 0.0) {
      let margemPraticada =
          ((valorVenda - valorCusto ?? 0) / valorCusto ?? 0) * 100;
      margemPraticada =
          Number.isNaN(margemPraticada) || !Number.isFinite(margemPraticada) ?
          0 :
          margemPraticada;
      this.precoForm.controls.margem1Praticada.setValue(margemPraticada);
    }
  }
  private gerarPrecosVariacoes(): ProdutoVariacaoPreco[] {
    return this.authFacade.grupoLogado.empresas.map(
        (p) => <ProdutoVariacaoPreco>{
          empresaId: p.id,
          valorVenda1: this.precoVenda ?? 0,
          valorVenda2: 0,
          valorCusto: 0,
          margem1Praticada: 0,
        });
  }
  private limparFormVariacoes() {
    this.tamanhos = [];
    this.cores = [];
    this.referencias = [];
    this.precoVenda = null;
    this.informacaoAdicional = null;
  }
  private monitorarSecao() {
    this.form.controls.secaoId.valueChanges.subscribe((secaoId: string) => {
      if (secaoId) {
        this.grupoFacade.buscarPorSecaoId({secaoId: secaoId, ativo: true});
      }
    });
  }
  private monitorarGrupo() {
    this.form.controls.grupoId.valueChanges.subscribe((grupoId: string) => {
      this.form.controls.subGrupoId?.setValue(null);
      this.subgrupoFacade.limparListaSubgrupos();
      if (grupoId) {
        this.subgrupoFacade.buscarPorGrupoId({grupoId: grupoId, ativo: true});
      }
    });
  }
  private monitorarProdutoTipo() {
    this.form.controls.produtoTipo.valueChanges.subscribe(
        (produtoTipo: ProdutoTipo) => {
          if (produtoTipo === ProdutoTipo.Variacao) {
            if (this.produtoPesado) {
              this.form.controls.produtoTipo.setValue(ProdutoTipo.Simples);
              this.toastrService.warning(
                  'Não é possível criar uma variação para um produto pesado.');
            }
            this.adicionarValidator(
                'variacoes',
                CustomValidators.minLengthArray(
                    1, 'Produto/Variação deve ter pelo menos uma variação'),
                this.form);
            this.removerValidator('produtoVariacaoSimples.precos', this.form);
          } else {
            this.removerValidator('variacoes', this.form);
            this.adicionarValidator(
                'produtoVariacaoSimples.precos',
                CustomValidators.minLengthArray(
                    1, 'Informe pelo menos um preço'),
                this.form);
          }
        });
  }
  private monitorarProdutoPesado() {
    this.form.controls.produtoPesado.valueChanges.subscribe(
        (produtoPesado: boolean) => {
          if (produtoPesado) {
            if (this.produtoTipoVariacao) {
              this.form.controls.produtoTipo.setValue(ProdutoTipo.Simples);
              this.toastrService.warning(
                  'Produtos pesados não podem ser do tipo \'VARIAÇÃO\'.');
            } else {
              this.produtoVariacaoSimplesFormGroup.controls.codigoBarras
                  .setValue('');
            }
            this.form.controls.produtoVendidoPor.setValue(
                ProdutoVendidoPor.Peso);
          }
        });
  }
  private monitorarProdutoVendidoPor() {
    this.form.controls.produtoVendidoPor.valueChanges.subscribe(
        (produtoVendidoPor: ProdutoVendidoPor) => {
          this.form.controls.produtoPesado.setValue(
              produtoVendidoPor == ProdutoVendidoPor.Peso);
        });
  }
  private preencherObjetoProdutoVariacao() {
    return ProdutoVariacao.criarProdutoVariacao(this.variacaoForm.value);
  }
  private preencherObjetoProdutoVariacaoPreco() {
    const produtoVariacaoPreco =
        ProdutoVariacaoPreco.criarProdutoVariacaoPreco(this.precoForm.value);
    produtoVariacaoPreco.prepararProdutoVariacaoPreco();
    return produtoVariacaoPreco;
  }
  private preencherObjetoProdutoVariacaoEstoque() {
    const produtoVariacaoEstoque =
        ProdutoVariacaoEstoque.criarProdutoVariacaoEstoque(
            this.estoqueForm.value);
    produtoVariacaoEstoque.prepararProdutoVariacaoEstoque();
    return produtoVariacaoEstoque;
  }
  private preencherObejtoFornecedorProduto() {
    return FornecedorProduto.from(this.fornecedorProdutoForm.value);
  }
  private preencherNcmSelecionado(ncm: Ncm) {
    const uf = this.authFacade.enderecoEmpresaLogada.uf;
    const ncmUf = ncm.ncmUf.find((ncmUf) => ncmUf.uf === uf);
    const regimeNormal = this.authFacade.empresaLogada.regimeTributario ==
        RegimeTributario.RegimeNormal;

    if (ncmUf) {
      this.tributacaoForm.controls.importadosFederal.setValue(
          ncmUf.importadosFederal);
      this.tributacaoForm.controls.nacionalFederal.setValue(
          ncmUf.nacionalFederal);
      this.tributacaoForm.controls.estadual.setValue(ncmUf.estadual);
      this.tributacaoForm.controls.municipal.setValue(ncmUf.municipal);
    }

    const ncmIcms = ncm.ncmIcms.find((ncmIcms) => ncmIcms.uf === uf);

    this.tributacaoForm.controls.origem.setValue(ncmIcms?.origem ?? '0');
    this.tributacaoForm.controls.produtoServico.setValue(
        ncmIcms?.produtoServico ?? '00');
    this.tributacaoForm.controls.icmsCstCsosnEntrada.setValue(
        ncmIcms?.icmsCstCsosnEntrada ?? (regimeNormal ? '00' : '102'));
    this.tributacaoForm.controls.icmsCstCsosnSaida.setValue(
        ncmIcms?.icmsCstCsosnSaida ?? (regimeNormal ? '00' : '102'));

    this.tributacaoForm.controls.naturezaReceitaCodigo.setValue(
        ncm.ncmPisCofinsIpi?.naturezaReceitaCodigo);

    this.tributacaoForm.controls.pisCofinsTipo.setValue(
        ncm.ncmPisCofinsIpi?.pisCofinsTipo ?? 'M');
    this.tributacaoForm.controls.pisAliquota.setValue(
        ncm.ncmPisCofinsIpi?.pisAliquota);
    this.tributacaoForm.controls.cofinsAliquota.setValue(
        ncm.ncmPisCofinsIpi?.cofinsAliquota);
    this.tributacaoForm.controls.pisCofinsCstEntrada.setValue(
        ncm.ncmPisCofinsIpi?.pisCofinsCstEntrada ?? '99');
    this.tributacaoForm.controls.pisCofinsCstSaida.setValue(
        ncm.ncmPisCofinsIpi?.pisCofinsCstSaida ?? '49');
    this.tributacaoForm.controls.ccsApurada.setValue(
        ncm.ncmPisCofinsIpi?.ccsApurada ?? '02');

    if (ncmIcms) {
      this.form.controls.cestCodigo.setValue(ncmIcms.cestCodigo);

      this.tributacaoForm.controls.icmsAliquotaSaida.setValue(
          Number(ncmIcms.icmsAliquotaSaida));
      this.tributacaoForm.controls.icmsAliquotaSaidaReducao.setValue(
          Number(ncmIcms.icmsAliquotaSaidaReducao));
      this.tributacaoForm.controls.icmsAliquotaSaidaFinal.setValue(
          Number(ncmIcms.icmsAliquotaSaidaFinal));

      this.tributacaoForm.controls.icmsAliquotaEntrada.setValue(
          Number(ncmIcms.icmsAliquotaEntrada));
      this.tributacaoForm.controls.icmsAliquotaEntradaReducao.setValue(
          Number(ncmIcms.icmsAliquotaEntradaReducao));
      this.tributacaoForm.controls.icmsAliquotaEntradaIva.setValue(
          Number(ncmIcms.icmsAliquotaEntradaIva));
    }

    if (ncm.ncmPisCofinsIpi) {
      this.tributacaoForm.controls.ipiAliquota.setValue(
          Number(ncm.ncmPisCofinsIpi.ipiAliquota));
      this.tributacaoForm.controls.ipiCstEntrada.setValue(
          ncm.ncmPisCofinsIpi.ipiCstEntrada);
      this.tributacaoForm.controls.ipiCstSaida.setValue(
          ncm.ncmPisCofinsIpi.ipiCstSaida);
    }
  }
  private setarVariacaoSimples(produto: Produto) {
    produto.produtoVariacaoSimples = produto.variacoes[0];
    const variacaoSimplesForm =
        this.criarVariacaoFormGroup(produto.produtoVariacaoSimples);
    this.form.setControl('produtoVariacaoSimples', variacaoSimplesForm);
  }
  private verificarEmpresaJaCadastradaNosPrecos(
      produtoVariacaoPreco: ProdutoVariacaoPreco,
  ) {
    const produtoVariacaoPrecos = this.variacaoForm ?
        (this.variacaoForm.controls.precos.value as ProdutoVariacaoPreco[]) :
        this.produtoVariacaoSimplesPreco;

    return produtoVariacaoPrecos.some(
        (e, index) => e.empresaId == produtoVariacaoPreco.empresaId &&
            index != this.precoEditado?.index);
  }
  private verificarAreaEstoqueJaCadastradaNosEstoques(
      produtoVariacaoEstoque: ProdutoVariacaoEstoque) {
    if (this.operacaoFormEstoque == OperacaoForm.Editar) return false;

    const produtoVariacaoEstoques = this.variacaoForm ?
        (this.variacaoForm.controls.estoques.value as
         ProdutoVariacaoEstoque[]) :
        this.produtoVariacaoSimplesEstoque;

    return produtoVariacaoEstoques.some(
        (estoque) =>
            estoque.areaEstoqueId == produtoVariacaoEstoque.areaEstoqueId);
  }
  private verificarEmpresaUnica(form: FormGroup<IProdutoVariacaoPrecoForm>) {
    if (this.empresaUnica) {
      form.controls.empresaId.setValue(this.authFacade.empresaLogada.id);
    }
  }
  private verificarEmpresaAreaEstoqueEdicao(variacaoEstoque:
                                                ProdutoVariacaoEstoque) {
    if (this.operacaoFormEstoque == OperacaoForm.Adicionar) return false;

    return variacaoEstoque.areaEstoqueId != this.estoqueEditado.areaEstoqueId;
  }
  private verificarCestObrigatorio() {
    const ncm = this.form.controls.ncmCodigo.value;
    const cest = this.form.controls.cestCodigo.value;
    const cstCsosnSaida =
        this.tributacaoForm?.controls?.icmsCstCsosnSaida?.value;

    let cstCsosnSaidaObrigaCEST = false;

    switch (cstCsosnSaida) {
      case '10':
      case '30':
      case '60':
      case '70':
      case '201':
      case '202':
      case '203':
      case '500':
        cstCsosnSaidaObrigaCEST = true;
        break;
    }
    return ncm && cstCsosnSaidaObrigaCEST && !cest;
  }
  async buscarMovimentacoes() {
    if (this?.movimentacoes?.length > 0) return;

    var movimentacoes = await this.produtoFacade.buscarMovimentacoes(
        this.form.controls.id.value);
    this.movimentacoes = movimentacoes;
  }
  async buscarTributacaoPorNcm() {
    if (!this.authFacade.enderecoEmpresaLogada?.uf) {
      this.toastrService.warning('Endereço da empresa não cadastrado.');
      return;
    }
    const ncmCodigo = this.form.controls.ncmCodigo.value;
    const ncmExcecao = this.form.controls.ncmExcecao.value;
    if (ncmCodigo && ncmExcecao) {
      this.loadingService.start();
      this.ncmFacade
          .buscarPorNcmCodigo(
              ncmCodigo, this.authFacade.enderecoEmpresaLogada.uf, ncmExcecao)
          .then((ncm: Ncm|NcmListagem) => {
            this.preencherNcmSelecionado(ncm as Ncm);
            this.loadingService.stop();
          })
          .catch(() => {
            this.loadingService.stop();
          });
    }
  }
  async gerarCodigoBarrasProdutoSimples() {
    if (this.produtoPesado) {
      const eanProdutoPesado =
          await this.produtoFacade.buscarEanDisponivelProdutoPesado();
      this.listaEanGerados = [eanProdutoPesado.toString()];
    } else {
      this.listaEanGerados = await this.produtoFacade.buscarEanDisponivel(1);
    }
  }
  async gerarCodigoBarrasParaProdutoVariacao() {
    let codigoBarras;
    this.dadosTabelaVariacoesCodigosBarras = [];

    const variacoesSemCodigoBarras =
        this.variacoes.filter((v) => !v.codigoBarras);

    this.listaEanGerados = await this.produtoFacade.buscarEanDisponivel(
        variacoesSemCodigoBarras.length);

    for (const [index, variacao] of variacoesSemCodigoBarras.entries()) {
      codigoBarras = this.listaEanGerados[index];

      this.dadosTabelaVariacoesCodigosBarras.push({
        tamanhoId: variacao.tamanhoId,
        corId: variacao.corId,
        referenciaId: variacao.referenciaId,
        codigoBarras,
      });
    }
  }
  async setarValoresPadroes() {
    if (this.operacaoForm != OperacaoForm.Editar) {
      this.form.controls.produtoTipo.setValue(ProdutoTipo.Simples);
      this.form.controls.quantidadeEntrada.setValue(1);
      this.form.controls.quantidadeSaida.setValue(1);
      this.form.controls.produtoVendidoPor.setValue(ProdutoVendidoPor.Unidade);
      this.form.controls.produtoPesado.setValue(false);
      this.form.controls.ativo.setValue(true);
      this.unidadesComerciaisSubscription =
          this.unidadeComercialFacade.unidadesComerciais$.subscribe((_) => {
            if (this.unidadeComercialFacade.unidadesComerciais?.length > 0) {
              const unidadePadrao =
                  this.unidadeComercialFacade.unidadesComerciais.find(
                      (unidadeComercial) => unidadeComercial.sigla === 'UN');
              const primeiraUnidade =
                  this.unidadeComercialFacade.unidadesComerciais[0];

              this.form.controls.unidadeComercialEntrada.setValue(
                  unidadePadrao ? unidadePadrao.sigla : primeiraUnidade.sigla);
              this.form.controls.unidadeComercialSaida.setValue(
                  unidadePadrao ? unidadePadrao.sigla : primeiraUnidade.sigla);
            }
          });

      this.secoesSubscription = this.secaoFacade.secoes$.subscribe((_) => {
        if (this.secaoFacade.secoes?.length > 0) {
          this.form.controls.secaoId.setValue(
              this.secaoFacade.secoes
                  .find((x) => x.descricao.toUpperCase() === 'GERAL')
                  .id);
        }
      });

      this.gruposSubscription = this.grupoFacade.grupos$.subscribe((_) => {
        if (this.grupoFacade.grupos?.length > 0) {
          this.form.controls.grupoId.setValue(
              this.grupoFacade.grupos
                  .find((x) => x.descricao.toUpperCase() === 'GERAL')
                  .id);
        }
      });

      this.subgruposSubscription =
          this.subgrupoFacade.subgrupos$.subscribe((_) => {
            if (this.subgrupoFacade.subgrupos?.length > 0) {
              this.form.controls.subGrupoId.setValue(
                  this.subgrupoFacade.subgrupos
                      .find((x) => x.descricao.toUpperCase() === 'GERAL')
                      .id);
            }
          });

      this.familiasSubscription =
          this.familiaFacade.familias$.subscribe((_) => {
            if (this.familiaFacade.familias?.length > 0) {
              this.form.controls.familiaId.setValue(
                  this.familiaFacade.familias
                      .find((x) => x.descricao.toUpperCase() === 'GERAL')
                      .id);
            }
          });

      if (this.tributacaoForm) {
        this.tributacaoForm?.controls?.pisAliquota.setValue(
            this.authFacade.empresaLogada.aliquotaPadraoPis);
        this.tributacaoForm.controls.cofinsAliquota.setValue(
            this.authFacade.empresaLogada.aliquotaPadraoCofins);
        this.tributacaoForm.controls.pisCofinsCstEntrada.setValue('99');
        this.tributacaoForm.controls.pisCofinsCstSaida.setValue('49');
        this.tributacaoForm.controls.ccsApurada.setValue('02');

        if (this.authFacade.empresaLogada.regimeTributario ==
                RegimeTributario.SimplesNacional ||
            this.authFacade.empresaLogada.regimeTributario ==
                RegimeTributario.Mei) {
          this.tributacaoForm.controls.pisCofinsTipo.setValue('I');
        } else if (
            this.authFacade.empresaLogada.regimeTributario ==
                RegimeTributario.RegimeNormal ||
            this.authFacade.empresaLogada.regimeTributario ==
                RegimeTributario.SimplesNacionalExcessoSublimite) {
          this.tributacaoForm.controls.pisCofinsTipo.setValue('T');
        }
      }
      const empresa = await this.empresaFacade.buscarPorId(
          this.authFacade.empresaLogada.id);
      if (!this.form.value.ncmCodigo) {
        this.form.controls.ncmCodigo.setValue(empresa.ncmPadrao);
        this.buscarTributacaoPorNcm();
      }
      if (!this.form.value.cestCodigo) {
        this.form.controls.cestCodigo.setValue(empresa.cestPadrao);
      }
    }
  }
  async setarTipoFormulario(produto?: Produto, path?: string) {
    if (path == 'editar') {
      this.operacaoForm = OperacaoForm.Editar;
    } else if (path == 'duplicar') {
      this.operacaoForm = OperacaoForm.Duplicar;
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.setarValoresPadroes();
    }
    if (produto) await this.preencherForm(Produto.from(produto));
  }
  abrirVariacaoModal(variacao?: ProdutoVariacao, index?: number) {
    this.variacaoForm = this.criarVariacaoFormGroup(variacao, index);
    if (variacao) {
      this.produtoVariacaoModalTitulo = 'Editar variação';
      this.operacaoFormProdutoVariacao = OperacaoForm.Editar;
    } else {
      this.produtoVariacaoModalTitulo = 'Nova variação';
      this.operacaoFormProdutoVariacao = OperacaoForm.Adicionar;
    }
    this.mostrarVariacaoModal = true;
  }
  abrirPrecoModal(produtoVariacaoPreco?: ProdutoVariacaoPreco, index?: number) {
    this.precoForm = this.criarPrecoFormGroup(produtoVariacaoPreco, index);
    this.precoForm.controls.empresaId.setValue(this.empresaPadraoDropdown.id);

    if (produtoVariacaoPreco) {
      this.produtoVariacaoPrecoModalTitulo = 'Editar preço';
      this.operacaoFormProdutoVariacaoPreco = OperacaoForm.Editar;
    } else {
      this.produtoVariacaoPrecoModalTitulo = 'Novo preço';
      this.operacaoFormProdutoVariacaoPreco = OperacaoForm.Adicionar;
    }
    this.verificarEmpresaUnica(this.precoForm);
    this.mostrarPrecoModal = true;
  }
  abrirEstoqueModal(
      produtoVariacaoEstoque?: ProdutoVariacaoEstoque, index?: number) {
    this.estoqueForm =
        this.criarEstoqueFormGroup(produtoVariacaoEstoque, index);
    if (produtoVariacaoEstoque) {
      this.variacaoEstoqueModalTitulo = 'Editar estoque';
      this.operacaoFormEstoque = OperacaoForm.Editar;
    } else {
      this.variacaoEstoqueModalTitulo = 'Novo estoque';
      this.operacaoFormEstoque = OperacaoForm.Adicionar;
    }
    this.mostrarEstoqueModal = true;
  }
  abrirFornecedorProdutoModal(
      fornecedorProduto?: FornecedorProduto, index?: number) {
    this.fornecedorProdutoForm =
        this.criarFornecedorProdutoFormGroup(fornecedorProduto, index);

    this.aplicarValidacoesFornecedorProduto();

    if (fornecedorProduto) {
      this.fornecedorProdutoModalTitulo = 'Editar Fornecedor';
      this.operacaoFormFornecedorProduto = OperacaoForm.Editar;
    } else {
      this.fornecedorProdutoModalTitulo = 'Novo Fornecedor';
      this.operacaoFormFornecedorProduto = OperacaoForm.Adicionar;
    }

    this.mostrarFornecedorProdutoModal = true;
  }
  abrirReferenciaCadastroRapidoModal() {
    this.mostrarProdutoVariacaoReferenciaCadastroRapidoModal = true;
  }
  abrirCorCadastroRapidoModal() {
    this.mostrarProdutoVariacaoCorCadastroRapidoModal = true;
  }
  abrirTamanhoCadastroRapidoModal() {
    this.mostrarProdutoVariacaoTamanhoCadastroRapidoModal = true;
  }
  abrirSecaoCadastroRapidoModal() {
    this.mostrarSecaoCadastroRapidoModal = true;
  }
  abrirGrupoCadastroRapidoModal() {
    this.mostrarGrupoCadastroRapidoModal = true;
  }
  abrirSubgrupoCadastroRapidoModal() {
    this.mostrarSubgrupoCadastroRapidoModal = true;
  }
  abrirFamiliaCadastroRapidoModal() {
    this.mostrarFamiliaCadastroRapidoModal = true;
  }
  abrirMarcaCadastroRapidoModal() {
    this.mostrarMarcaCadastroRapidoModal = true;
  }
  abrirUnidadeComercialCadastroRapidoModal() {
    this.mostrarUnidadeComercialCadastroRapidoModal = true;
  }
  abrirTabelaNutricionalCadastroRapidoModal() {
    this.mostrarTabelaNutricionalCadastroRapidoModal = true;
  }
  abrirNaturezaReceitaCadastroRapidoModal() {
    this.mostrarNaturezaReceitaCadastroRapidoModal = true;
  }
  abrirCestBuscaRapidaModal() {
    this.mostrarCestBuscaRapidaModal = true;
  }
  abrirNcmBuscaRapidaModal() {
    this.mostrarNcmBuscaRapidaModal = true;
  }
  abrirGeradorEanModal() {
    this.mostrarGeradorEanModal = true;
  }
  abrirFornecedorBuscaRapidaModal() {
    this.mostrarFornecedorBuscaRapidaModal = true;
  }
  adicionarEanRelacionado() {
    const form = this.buscarFormProdutoSimplesOuVariacao();
    const eanRelacionados = form.controls.eanRelacionados as FormArray;

    if (!this.eanRelacionado) {
      this.toastrService.warning('Informe um Código de Barras');
      return;
    }
    if (eanRelacionados.controls.some(
            (control) => control.value == this.eanRelacionado)) {
      this.toastrService.warning('Código de Barras já adicionado');
      return;
    }

    eanRelacionados.push(new FormControl(this.eanRelacionado));
    this.eanRelacionado = null;
  }
  adicionarVariacoes() {
    if (!this.tamanhos?.length && !this.cores?.length &&
        !this.referencias?.length) {
      this.toastrService.info(
          'Preencha ao menos um tamanho, cor ou referência para adicionar as variações.');
      return;
    }

    if (this.tamanhos?.length && this.cores?.length &&
        this.referencias?.length) {
      for (const tamanho of this.tamanhos) {
        for (const cor of this.cores) {
          for (const referencia of this.referencias) {
            const variacao = <ProdutoVariacao>{
              corId: cor,
              tamanhoId: tamanho,
              referenciaId: referencia,
              precos: this.gerarPrecosVariacoes(),
              informacaoAdicional: this.informacaoAdicional,
              dimensionamento: this.dimensionamento
            };
            if (!this.variacoes.some(
                    (v) => v.corId == cor && v.tamanhoId == tamanho &&
                        v.referenciaId == referencia)) {
              this.adicionarProdutoVariacaoFormGroup(variacao);
            }
          }
        }
      }
      this.limparFormVariacoes();
      return;
    }

    if (this.tamanhos?.length && this.cores?.length) {
      for (const tamanho of this.tamanhos) {
        for (const cor of this.cores) {
          const variacao = <ProdutoVariacao>{
            corId: cor,
            tamanhoId: tamanho,
            precos: this.gerarPrecosVariacoes(),
            informacaoAdicional: this.informacaoAdicional,
            dimensionamento: this.dimensionamento
          };
          if (!this.variacoes.some(
                  (v) => v.corId == cor && v.tamanhoId == tamanho &&
                      !v.referenciaId)) {
            this.adicionarProdutoVariacaoFormGroup(variacao);
          }
        }
      }
      this.limparFormVariacoes();
      return;
    }

    if (this.tamanhos?.length && this.referencias?.length) {
      for (const tamanho of this.tamanhos) {
        for (const referencia of this.referencias) {
          const variacao = <ProdutoVariacao>{
            tamanhoId: tamanho,
            referenciaId: referencia,
            precos: this.gerarPrecosVariacoes(),
            informacaoAdicional: this.informacaoAdicional,
            dimensionamento: this.dimensionamento
          };
          if (!this.variacoes.some(
                  (v) => !v.corId && v.tamanhoId == tamanho &&
                      v.referenciaId == referencia)) {
            this.adicionarProdutoVariacaoFormGroup(variacao);
          }
        }
      }
      this.limparFormVariacoes();
      return;
    }

    if (this.cores?.length && this.referencias?.length) {
      for (const cor of this.cores) {
        for (const referencia of this.referencias) {
          const variacao = <ProdutoVariacao>{
            corId: cor,
            referenciaId: referencia,
            precos: this.gerarPrecosVariacoes(),
            informacaoAdicional: this.informacaoAdicional,
            dimensionamento: this.dimensionamento
          };
          if (!this.variacoes.some(
                  (v) => v.corId == cor && !v.tamanhoId &&
                      v.referenciaId == referencia)) {
            this.adicionarProdutoVariacaoFormGroup(variacao);
          }
        }
      }
      this.limparFormVariacoes();
      return;
    }

    if (this.tamanhos?.length) {
      for (const tamanho of this.tamanhos) {
        const variacao = <ProdutoVariacao>{
          tamanhoId: tamanho,
          precos: this.gerarPrecosVariacoes(),
          informacaoAdicional: this.informacaoAdicional,
          dimensionamento: this.dimensionamento
        };
        if (!this.variacoes.some(
                (v) => !v.corId && v.tamanhoId == tamanho && !v.referenciaId)) {
          this.adicionarProdutoVariacaoFormGroup(variacao);
        }
      }
      this.limparFormVariacoes();
      return;
    }

    if (this.cores?.length) {
      for (const cor of this.cores) {
        const variacao = <ProdutoVariacao>{
          corId: cor,
          precos: this.gerarPrecosVariacoes(),
          informacaoAdicional: this.informacaoAdicional,
          dimensionamento: this.dimensionamento
        };
        if (!this.variacoes.some(
                (v) => v.corId == cor && !v.tamanhoId && !v.referenciaId)) {
          this.adicionarProdutoVariacaoFormGroup(variacao);
        }
      }
      this.limparFormVariacoes();
      return;
    }

    if (this.referencias?.length) {
      for (const referencia of this.referencias) {
        const variacao = <ProdutoVariacao>{
          referenciaId: referencia,
          precos: this.gerarPrecosVariacoes(),
          informacaoAdicional: this.informacaoAdicional,
          dimensionamento: this.dimensionamento
        };
        if (!this.variacoes.some(
                (v) =>
                    !v.corId && !v.tamanhoId && v.referenciaId == referencia)) {
          this.adicionarProdutoVariacaoFormGroup(variacao);
        }
      }
      this.limparFormVariacoes();
      return;
    }
  }
  abrirSetorCadastroRapidoModal() {
    this.mostrarSetorCadastroRapidoModal = true;
  }
  buscarFoto(index: number) {
    if (index.toString().length) {
      const produtoVariacaoFoto = this.variacaoForm ?
          this.produtoVariacaoFoto[index] :
          this.produtoVariacaoSimplesFoto[index];

      if (produtoVariacaoFoto.caminho) {
        return `${API.ERP_BASE_URL}/${produtoVariacaoFoto.caminho}`;
      } else if (produtoVariacaoFoto.fotoBase64) {
        return produtoVariacaoFoto.fotoBase64;
      }
    }
    return '';
  }
  buscarFormProdutoSimplesOuVariacao() {
    return this.produtoTipoSimples ? this.produtoVariacaoSimplesFormGroup :
                                     this.variacaoForm;
  }
  criarForm() {
    this.form = new FormGroup<IProdutoForm>({
      id: new FormControl(null),
      secaoId: new FormControl(null),
      grupoId: new FormControl(null),
      subGrupoId: new FormControl(null),
      familiaId: new FormControl(null),
      tabelaNutricionalId: new FormControl(null),
      descricao: new FormControl(
          null,
          [
            CustomValidators.required('Nome do produto é obrigatório'),
            CustomValidators.maxLength(60, 'Tamanho máximo: 60 caracteres'),
          ]),
      descricaoReduzida: new FormControl(
          null,
          [
            CustomValidators.required('Nome do produto reduzido é obrigatório'),
            CustomValidators.maxLength(35, 'Tamanho máximo: 35 caracteres'),
          ]),
      ncmCodigo: new FormControl(null),
      ncmExcecao: new FormControl('00'),
      cestCodigo: new FormControl(null),
      produtoIdAssociado: new FormControl(null),
      unidadeComercialEntrada: new FormControl(
          null,
          CustomValidators.required(
              'Unidade de comercial entrada é obrigatória')),
      unidadeComercialSaida: new FormControl(
          null,
          CustomValidators.required(
              'Unidade de comercial saída é obrigatória')),
      quantidadeEntrada: new FormControl(
          1, CustomValidators.required('Quantidade de entrada é obrigatória')),
      quantidadeSaida: new FormControl(
          1, CustomValidators.required('Quantidade de saída é obrigatória')),
      produtoPesadoTipo: new FormControl('U'),
      produtoTipo: new FormControl(
          ProdutoTipo.Simples,
          CustomValidators.required('Tipo de produto é obrigatório')),
      produtoVendidoPor: new FormControl(ProdutoVendidoPor.Unidade),
      produtoPesado: new FormControl(false),
      ativarVasilhame: new FormControl(false),
      etiquetaGerar: new FormControl(false),
      composicaoItem: new FormControl(false),
      decomposicaoEntradaNotaFiscal: new FormControl(false),
      movimentarEstoque: new FormControl(true),
      servico: new FormControl(false),
      bloquearVenda: new FormControl(false),
      pedirDocumentoVendaPdv: new FormControl(false),
      permitirDesconto: new FormControl(false),
      permitirDescricaoComplementar: new FormControl(false),
      permitirAlterarPrecoPdv: new FormControl(false),
      permitirCotacao: new FormControl(false),
      permitirComissao: new FormControl(false),
      permitirTabelaNutricional: new FormControl(false),
      permitirProdutoAssociado: new FormControl(false),
      habilitarNoEcommerce: new FormControl(false),
      produtoVariacaoSimples: ProdutoVariacaoForm.criarForm(),
      tributacao: ProdutoTributacaoForm.criarForm(),
      food: ProdutoFoodForm.criarForm(),
      variacoes: new FormArray<FormGroup<IProdutoVariacaoForm>>([]),
      ativo: new FormControl(true),
      codigoSistema: new FormControl(null),
      codigoRapido: new FormControl(
          null,
          [CustomValidators.maxLength(20, 'Tamanho máximo: 20 caracteres')]),
      quantidadeSaidaProdutoAssociado: new FormControl(null),
      diasDeValidade: new FormControl(null),
      createdOn: new FormControl(null),
      lastModifiedOn: new FormControl(null),
      marcaId: new FormControl(null),
      modelo: new FormControl(null),
      setores: new FormControl(null),
    });
  }
  calcularValores(campo: string) {
    setTimeout(() => {
      let valorCusto = this.precoForm.controls.valorCusto.value;
      let valorVenda = this.precoForm.controls.valorVenda1.value;
      let margemFixa = this.precoForm.controls.margem1Fixa.value;
      let margemPraticada = this.precoForm.controls.margem1Praticada.value;

      if (campo == 'valorVenda1') {
        this.calcularMargemPraticada(valorVenda, valorCusto);
      } else if (campo == 'valorCusto') {
        if (margemPraticada) {
          this.calcularValorVenda(margemPraticada, valorCusto);
        } else if (margemFixa) {
          this.calcularValorVenda(margemFixa, valorCusto);
        } else {
          this.calcularValorVenda(0, valorCusto);
        }
      } else if (campo == 'margem1Praticada') {
        if (margemPraticada) {
          this.calcularValorVenda(margemPraticada, valorCusto);
        } else if (margemFixa) {
          this.calcularValorVenda(margemFixa, valorCusto);
        }
      } else if (campo == 'margem1Fixa') {
        if (margemFixa) {
          this.calcularValorVenda(margemFixa, valorCusto);
        } else if (margemPraticada) {
          this.calcularValorVenda(margemPraticada, valorCusto);
        }
      }
    }, 100);
  }
  criarFornecedorProdutoFormGroup(
      fornecedorProduto?: FornecedorProduto, index?: number) {
    if (index >= 0) {
      this.operacaoFormFornecedorProduto = OperacaoForm.Editar;
      this.fornecedorProdutoEditado = {index: index};
    }

    return FornecedorProdutoForm.criarForm(fornecedorProduto);
  }
  excluirVariacao(index: number) {
    this.produtoVariacaoFormArray.removeAt(index);
  }
  excluirPreco(index: number) {
    const form = this.buscarFormProdutoSimplesOuVariacao();

    const produtoVariacaoPrecoFormArray = form.controls.precos;

    produtoVariacaoPrecoFormArray.removeAt(index);
  }
  excluirEstoque(estoque: ProdutoVariacaoEstoque, index: number) {
    const form = this.buscarFormProdutoSimplesOuVariacao();

    const produtoVariacaoEstoqueFormArray = form.controls.estoques;
    const produtoVariacaoEstoque = produtoVariacaoEstoqueFormArray.value as [];

    let itemRemovido = produtoVariacaoEstoque.filter(
        (item: ProdutoVariacaoEstoque) =>
            item.areaEstoqueId == estoque.areaEstoqueId)[index];
    let indexItemRemovido = produtoVariacaoEstoque.indexOf(itemRemovido);
    produtoVariacaoEstoqueFormArray.removeAt(indexItemRemovido);
  }
  excluirFoto(index: number) {
    const form = this.buscarFormProdutoSimplesOuVariacao();

    const produtoVariacaoFotoFormArray = form.controls.fotos;

    produtoVariacaoFotoFormArray.removeAt(index);
    this.reordenarProdutoVariacaoFoto();
  }
  excluirFornecedorProduto(index: number) {
    const form = this.buscarFormProdutoSimplesOuVariacao();

    const fornecedoresProdutoFormArray = form.controls.fornecedores;

    fornecedoresProdutoFormArray.removeAt(index);
  }
  excluirEanRelacionado(index: number) {
    const form = this.buscarFormProdutoSimplesOuVariacao();

    const eanRelacionadosFormArray = form.controls.eanRelacionados;

    eanRelacionadosFormArray.removeAt(index);
  }
  fecharVariacaoModal() {
    this.variacaoForm = null;
    this.mostrarVariacaoModal = false;
  }
  fecharPrecoModal() {
    this.precoForm = null;
    this.precoEditado = null;
    this.mostrarPrecoModal = false;
  }
  fecharEstoqueModal() {
    this.estoqueForm = null;
    this.estoqueEditado = null;
    this.mostrarEstoqueModal = false;
  }
  fecharReferenciaCadastroRapidoModal() {
    this.mostrarProdutoVariacaoReferenciaCadastroRapidoModal = false;
  }
  fecharCorCadastroRapidoModal() {
    this.mostrarProdutoVariacaoCorCadastroRapidoModal = false;
  }
  fecharTamanhoCadastroRapidoModal() {
    this.mostrarProdutoVariacaoTamanhoCadastroRapidoModal = false;
  }
  fecharSecaoCadastroRapidoModal() {
    this.mostrarSecaoCadastroRapidoModal = false;
  }
  fecharGrupoCadastroRapidoModal() {
    this.mostrarGrupoCadastroRapidoModal = false;
  }
  fecharSubgrupoCadastroRapidoModal() {
    this.mostrarSubgrupoCadastroRapidoModal = false;
  }
  fecharFamiliaCadastroRapidoModal() {
    this.mostrarFamiliaCadastroRapidoModal = false;
  }
  fecharMarcaCadastroRapidoModal() {
    this.mostrarMarcaCadastroRapidoModal = false;
  }
  fecharUnidadeComercialCadastroRapidoModal() {
    this.mostrarUnidadeComercialCadastroRapidoModal = false;
  }
  fecharTabelaNutricionalCadastroRapidoModal() {
    this.mostrarTabelaNutricionalCadastroRapidoModal = false;
  }
  fecharNaturezaReceitaCadastroRapidoModal() {
    this.mostrarNaturezaReceitaCadastroRapidoModal = false;
  }
  fecharProdutoBuscaRapidaModal() {
    this.mostrarProdutoBuscaRapidaModal = false;
  }
  fecharCestBuscaRapidaModal() {
    this.mostrarCestBuscaRapidaModal = false;
  }
  fecharNcmBuscaRapidaModal() {
    this.mostrarNcmBuscaRapidaModal = false;
  }
  fecharGeradorEanModal() {
    this.mostrarGeradorEanModal = false;
    this.listaEanGerados = null;
    this.dadosTabelaVariacoesCodigosBarras = null;
  }
  fecharFornecedorProdutoModal() {
    this.mostrarFornecedorProdutoModal = false;
  }
  fecharFornecedorBuscaRapidaModal() {
    this.mostrarFornecedorBuscaRapidaModal = false;
  }
  fecharSetorCadastroRapidoModal() {
    this.mostrarSetorCadastroRapidoModal = false;
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }

    if (this.verificarCestObrigatorio()) {
      this.toastrService.warning(
          'CEST obrigatório para CST/CSOSN saída informado.');
      return false;
    }
    return true;
  }
  limparVariacoes() {
    this.produtoVariacaoFormArray.removeWhere(
        (p: any) => p.controls.id.value == null);
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  monitorarCamposProduto() {
    this.monitorarSecao();
    this.monitorarGrupo();
    this.monitorarProdutoTipo();
    this.monitorarProdutoPesado();
    this.monitorarProdutoVendidoPor();
  }
  preencherDescricaoReduzida(event: any) {
    this.form.controls.descricaoReduzida.setValue(
        (event.target.value as string).substring(0, 35));
  }
  preencherCodigosBarras(produto: Produto) {
    if (this.listaEanGerados) {
      if (this.produtoTipoSimples) {
        produto.variacoes.first().codigoBarras = this.listaEanGerados.first();
      } else {
        for (const [index, variacao] of produto.variacoes
                 .filter((v) => !v.codigoBarras)
                 .entries()) {
          variacao.codigoBarras = this.listaEanGerados[index];
        }
      }
    }
  }
  reordenarProdutoVariacaoFoto() {
    if (this.variacaoForm) {
      this.produtoVariacaoFoto.forEach((e: any, index: number) => {
        e.ordem = index;
      });
    } else {
      this.produtoVariacaoSimplesFoto.forEach((e: any, index: number) => {
        e.ordem = index;
      });
    }
  }
  salvarVariacao() {
    if (this.variacaoForm.valid) {
      const variacao = this.preencherObjetoProdutoVariacao();
      if (this.operacaoFormProdutoVariacao === OperacaoForm.Adicionar) {
        this.adicionarProdutoVariacaoFormGroup(variacao);
      } else {
        const index = this.variacaoEditada.index;
        this.produtoVariacaoFormArray.removeAt(index);
        this.produtoVariacaoFormArray.insert(
            index, this.criarVariacaoFormGroup(variacao));
      }
      this.fecharVariacaoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.variacaoForm);
    }
  }
  salvarPreco() {
    if (this.precoForm.valid) {
      const variacaoPreco = this.preencherObjetoProdutoVariacaoPreco();

      const form = this.buscarFormProdutoSimplesOuVariacao();

      if (this.verificarEmpresaJaCadastradaNosPrecos(variacaoPreco)) {
        this.errorMessagesFacade.mostrarMensagens(
            'Preço já cadastrado para esta empresa.');
        return;
      }
      if (this.verificarTodasEmpresasJaCadastradasNosPrecos(form)) {
        this.errorMessagesFacade.mostrarMensagens(
            'Todas as empresas já cadastradas nos preços.');
        return;
      }
      if (this.operacaoFormProdutoVariacaoPreco === OperacaoForm.Adicionar) {
        this.adicionarProdutoVariacaoPreco(variacaoPreco, form);
      } else {
        this.alterarProdutoVariacaoPreco(variacaoPreco, form);
      }
      this.fecharPrecoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.precoForm);
    }
  }
  verificarTodasEmpresasJaCadastradasNosPrecos(
      form: FormGroup<IProdutoVariacaoForm>) {
    const precos = (form.controls.precos.value as []);
    const empresas = this.authFacade.grupoLogado.empresas;

    if (this.operacaoFormProdutoVariacaoPreco === OperacaoForm.Adicionar &&
        precos.length == empresas.length) {
      return true;
    }
    return false;
  }
  salvarEstoque() {
    if (this.estoqueForm.valid) {
      const variacaoEstoque = this.preencherObjetoProdutoVariacaoEstoque();

      const form = this.buscarFormProdutoSimplesOuVariacao();

      if (this.verificarAreaEstoqueJaCadastradaNosEstoques(variacaoEstoque)) {
        this.errorMessagesFacade.mostrarMensagens(
            'Área de estoque já cadastrada.');
        return;
      }

      if (this.verificarEmpresaAreaEstoqueEdicao(variacaoEstoque)) {
        this.errorMessagesFacade.mostrarMensagens(
            'Área de estoque selecionada difere do estoque em edição.');
        return;
      }

      const areaEstoqueId = variacaoEstoque.areaEstoqueId;

      if (this.operacaoFormEstoque === OperacaoForm.Adicionar) {
        this.adicionarProdutoVariacaoEstoque(
            variacaoEstoque, areaEstoqueId, form);
      } else {
        this.alterarProdutoVariacaoEstoque(
            variacaoEstoque, areaEstoqueId, form);
      }
      this.fecharEstoqueModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.estoqueForm);
    }
  }
  salvarFoto(evento: any) {
    if (evento.target.files.length > 0) {
      const files = evento.target.files as FileList;
      const keys = Object.keys(files);
      keys.forEach(async (key) => {
        const file = files[Number(key)];
        const fotoBase64 = await this.fileService.imagemParaBase64(file);

        const ordem = this.variacaoForm ?
            this.produtoVariacaoFoto.length :
            this.produtoVariacaoSimplesFoto.length;

        const novaImagem = <ProdutoVariacaoFoto>{
          ativo: true,
          ordem: ordem,
          fotoBase64: fotoBase64,
          fotoNome: file.name,
        };
        this.adicionarProdutoVariacaoFotoFormGroup(novaImagem);
      });
    }
  }
  salvarFornecedorProduto() {
    if (this.fornecedorProdutoForm.valid) {
      const fornecedorProduto = this.preencherObejtoFornecedorProduto();

      if (this.operacaoFormFornecedorProduto === OperacaoForm.Adicionar) {
        this.adicionarFornecedorProdutoFormGroup(fornecedorProduto);
      } else {
        this.alterarFornecedorProdutoFormGroup(fornecedorProduto);
      }
      this.fecharFornecedorProdutoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(
          this.fornecedorProdutoForm);
    }
  }
  setarFamiliaCadastrada(familia: Familia) {
    this.form.controls.familiaId.setValue(familia.id);
  }
  setarMarcaCadastrada(marca: Marca) {
    this.form.controls.marcaId.setValue(marca.id);
  }
  setarGrupoCadastrado(grupo: Grupo) {
    this.form.controls.secaoId.setValue(grupo.secaoId);
    this.form.controls.grupoId.setValue(grupo.id);
  }
  setarProdutoSelecionado(produtoSelecionado: ProdutoSimplificadoListagem[]) {
    if (produtoSelecionado?.length) {
      if (this.operacaoFormEditar) {
        const produtoId = this.form.controls.id.value;
        if (produtoSelecionado.first().id === produtoId) {
          this.toastrService.error(
              'Desculpe, este é o produto que você está editando.');
          return;
        }
      }
      this.form.controls.produtoIdAssociado.setValue(
          produtoSelecionado.first().id);
      this.fecharProdutoBuscaRapidaModal();
    }
  }
  setarReferenciaCadastrada(produtoVariacaoReferencia:
                                ProdutoVariacaoReferencia) {
    this.variacaoForm?.controls?.referenciaId?.setValue(
        produtoVariacaoReferencia.id);
  }
  setarCorCadastrada(produtoVariacaoCor: ProdutoVariacaoCor) {
    this.variacaoForm?.controls?.corId?.setValue(produtoVariacaoCor.id);
  }
  setarTamanhoCadastrada(produtoVariacaoTamanho: ProdutoVariacaoTamanho) {
    this.variacaoForm?.controls?.tamanhoId?.setValue(produtoVariacaoTamanho.id);
  }
  setarSecaoCadastrada(secao: Secao) {
    this.form.controls.secaoId.setValue(secao.id);
  }
  setarSubGrupoCadastrado(subGrupo: Subgrupo) {
    this.form.controls.subGrupoId.setValue(subGrupo.id);
  }
  setarCestSelecionado(cestSelecionado: Cest) {
    if (cestSelecionado) {
      this.form.controls.cestCodigo.setValue(cestSelecionado.cestCodigo);
      this.fecharCestBuscaRapidaModal();
    }
  }
  setarNcmSelecionado(ncm: Ncm) {
    if (ncm) {
      this.form.controls.ncmCodigo.setValue(ncm.ncmCodigo);
      this.form.controls.ncmExcecao.setValue(
          ncm.ncmExcecaoTipi.toString().padLeft('0', 2));
      this.fecharNcmBuscaRapidaModal();
    }
  }
  setarFornecedorSelecionado(fornecedor: FornecedorListagem) {
    if (fornecedor) {
      this.fornecedorProdutoForm.controls.fornecedorId.setValue(fornecedor.id);
      this.fornecedorProdutoForm.controls.fornecedorNome.setValue(
          fornecedor.nomeFantasia ?? fornecedor.nomeRazaoSocial);
      this.fornecedorProdutoForm.controls.fornecedorDocumento.setValue(
          fornecedor.documento);
      this.fecharFornecedorBuscaRapidaModal();
    }
  }
}
