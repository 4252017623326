<app-menu-top-skeleton *ngIf="!opcoes"></app-menu-top-skeleton>

<div
  *ngIf="(linkYouTube || tour) && opcoes"
  class="flex justify-content-end mx-2"
>
  <div class="flex align-items-center">
    <i
      *ngIf="linkYouTube"
      class="pi pi-youtube text-2xl cursor-pointer text-red-500 mx-4"
      pTooltip="Vídeo Tutorial da Tela"
      tooltipPosition="bottom"
      (click)="op.toggle($event)"
    ></i>
    <p-overlayPanel #op [dismissable]="false">
      <div class="flex flex-column gap-3">
        <div>
          <span class="font-medium text-900 block mb-2">
            Vídeo Tutorial da Tela
          </span>
          <div>
            <iframe
              width="426"
              height="240"
              [src]="linkYouTubeSanitized"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
            ></iframe>
          </div>
        </div>
      </div>
    </p-overlayPanel>
    <i
      *ngIf="tour"
      class="pi pi-flag-fill text-primary text-xl cursor-pointer"
      pTooltip="Iniciar Tour"
      tooltipPosition="bottom"
      (click)="startTour()"
    ></i>
  </div>
</div>

<!-- #region Menu Superior Tela Listagem-->
<div class="sticky" *ngIf="tipoFiltro && opcoes">
  <div class="card">
    <div class="formgrid grid">
      <div class="mr-auto" *ngIf="opcoes?.linkVoltar">
        <p-button
          icon="pi pi-arrow-left"
          styleClass="ml-3 btn-four"
          tooltipPosition="bottom"
          pTooltip="Voltar"
          data-cy="btn-menu-top-voltar"
          [routerLink]="[opcoes?.linkVoltar]"
        ></p-button>
      </div>
      <div class="col" *ngIf="!opcoes.esconderCampoTexto">
        <app-text-input-ngmodel
          #inputPesquisa
          [mask]="opcoes?.mascara"
          [placeholder]="opcoes?.placeholder"
          [showClear]="true"
          (modelChange)="filtrar($event)"
          [(model)]="value"
        ></app-text-input-ngmodel>
      </div>
      <div class="ml-auto mr-2">
        <p-button
          icon="pi pi-file"
          styleClass="mx-1 "
          tooltipPosition="bottom"
          *ngIf="opcoes?.mostrarBotaoRelatorio"
          [pTooltip]="opcoes?.tooltipBotaoRelatorio"
          [routerLink]="opcoes?.linkRelatorio"
        ></p-button>
        <p-button
          *ngIf="opcoes?.mostrarBotaoBuscaAvancada"
          icon="pi pi-filter"
          styleClass="mx-1 btn-two"
          pTooltip="Busca avançada"
          tooltipPosition="bottom"
          [badge]="filtrosCount"
          [ngClass]="{ 'filtro-button-badge': filtrosCount }"
          (click)="mostrarBuscarAvancada()"
        ></p-button>
        <p-button
          *ngIf="opcoes?.mostrarBotaoMaisOpcoes"
          (click)="mostrarMaisOpcoes($event)"
          icon="pi pi-bars"
          styleClass="mx-1 btn-six"
          pTooltip="Mais opções"
          tooltipPosition="bottom"
        ></p-button>
        <p-menu
          *ngIf="opcoes?.mostrarBotaoMaisOpcoes"
          #maisOpcoes
          [popup]="true"
          [model]="opcoes?.maisOpcoesItens"
          appendTo="body"
        >
        </p-menu>
        <p-button
          *ngIf="opcoes?.mostrarBotaoAdicionar"
          icon="pi pi-plus"
          styleClass="mx-1 btn-one"
          [pTooltip]="opcoes?.tooltipAdicionar"
          routerLink="adicionar"
          [tooltipPosition]="opcoes?.tooltipPosition ?? 'bottom'"
        ></p-button>
      </div>
      <div class="ml-auto" *ngIf="dropdownEmpresas">
        <ng-container *ngTemplateOutlet="dropdownEmpresas"></ng-container>
      </div>
    </div>
  </div>
</div>

<div id="busca-avancada" class="mb-2 top-menu-panel">
  <div subheader="Busca avançada" class="card mb-4 mt-4">
    <ng-container *ngTemplateOutlet="buscaAvancada"></ng-container>
    <div class="col-12 text-right">
      <p-button styleClass="m-1 btn-two" (onClick)="limparCampos()"
        >Limpar campos</p-button
      ><p-button styleClass="m-1 btn-one" (onClick)="buscar()">Buscar</p-button>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Menu Superior Tela Cadastro -->
<div class="sticky mb-4" *ngIf="tipoCadastro && opcoes">
  <div class="card">
    <div class="formgrid grid">
      <div
        class="mr-auto ml-2"
        *ngIf="opcoes?.linkVoltar || opcoes?.voltarCommand"
      >
        <p-button
          icon="pi pi-arrow-left"
          styleClass="ml-2 btn-four"
          tooltipPosition="bottom"
          pTooltip="Voltar"
          class="btn btn-primary"
          data-cy="btn-menu-top-voltar"
          [routerLink]="[opcoes?.linkVoltar]"
          (onClick)="opcoes?.voltarCommand()"
        ></p-button>
      </div>
      <div class="ml-auto mr-2">
        <p-button
          icon="pi pi-eye"
          styleClass="mx-1 "
          tooltipPosition="bottom"
          *ngIf="opcoes?.mostrarBotaoVisualizar"
          [pTooltip]="opcoes?.tooltipBotaoVisualizar"
          (onClick)="visualizarClicado()"
        ></p-button>
        <div id="btnSalvar" styleClass="mx-1">
          <p-button
            icon="pi pi-save"
            *ngIf="!mostrarSpinner"
            tooltipPosition="bottom"
            pTooltip="Salvar"
            class="btn btn-primary"
            data-cy="btn-menu-top-salvar"
            (onClick)="salvarClicado()"
            [disabled]="opcoes?.bloquearBotaoSalvar"
          ></p-button>
        </div>
        <p-button
          icon="pi pi-spin pi-spinner"
          *ngIf="mostrarSpinner"
          styleClass="mx-1"
          tooltipPosition="bottom"
          pTooltip="Salvando"
          class="btn btn-primary"
          [disabled]="true"
        >
        </p-button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
