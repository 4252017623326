<p-tabView *ngIf="setorForm && setorForm?.form" [scrollable]="true">
  <p-tabPanel header="Dados do Impressora">
    <div class="formgrid grid">
      <!-- #region DESCRIÇÃO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Descrição" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [control]="setorForm.form.controls?.descricao"
          [maxlength]="60"
          [lengthOnlyNumber]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region IMPRESSORA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          texto="Impressora"
          [obrigatorio]="true"
          [cadastroRapido]="true"
          (aoClicarAdicionar)="abrirImpressoraCadastroRapidoModal()"
        ></app-custom-label>
        <app-dropdown-input
          optionValue="id"
          optionLabel="descricao"
          [options]="impressoras"
          [control]="setorForm.form.controls.impressoraId"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region QUANTIDADE DE VIAS -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Qtd. de Vias" [obrigatorio]="true">
        </app-custom-label>
        <app-numeric-input
          [control]="setorForm.form.controls?.quantidadeVia"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->
      <!-- #region QUANTIDADE DE CASAS DECIMAIS -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Qtd. de Casas Decimais" [obrigatorio]="true">
        </app-custom-label>
        <app-numeric-input
          [control]="setorForm.form.controls?.quantidadeCasaDecimal"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->
      <!-- #region CODIGO EXTERNO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Código Externo"> </app-custom-label>
        <app-text-input
          [control]="setorForm.form.controls?.codigoExterno"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region EMPRESAS -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Empresa" [obrigatorio]="true"></app-custom-label>
        <app-dropdown-input
          optionLabel="nomeFantasia"
          optionValue="id"
          [options]="empresas"
          [control]="setorForm.form.controls?.empresaId"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region EXIBIR VALOR -->
      <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-5">
        <app-checkbox-input
          label="Exibir Valor"
          controlName="exibirValor"
          [control]="setorForm?.form.controls.exibirValor"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
      <!-- #region ATIVO -->
      <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-5">
        <app-checkbox-input
          label="Ativo"
          controlName="ativo"
          [control]="setorForm?.form.controls.ativo"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
  </p-tabPanel>
</p-tabView>

<app-impressora-cadastro-rapido-modal
  [mostrarModal]="setorForm?.mostrarImpressoraCadastroRapidoModal"
  (aoCancelar)="fecharImpressoraCadastroRapidoModal()"
  (aoSalvar)="setarImpressoraCadastrada($event)"
></app-impressora-cadastro-rapido-modal>
