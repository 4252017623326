import {Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';
import {Cest} from '@app/abstraction/domain/entities/fiscal/cest/cest.entity';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {Familia} from '@app/abstraction/domain/entities/produtos/familia/familia.entity';
import {Grupo} from '@app/abstraction/domain/entities/produtos/grupo/grupo.entity';
import {Marca} from '@app/abstraction/domain/entities/produtos/marca/marca.entity';
import {ProdutoVariacaoCor} from '@app/abstraction/domain/entities/produtos/produto-variacao-cor/produto-variacao-cor.entity';
import {ProdutoVariacaoReferencia} from '@app/abstraction/domain/entities/produtos/produto-variacao-referencia/produto-variacao-referencia.entity';
import {ProdutoVariacaoTamanho} from '@app/abstraction/domain/entities/produtos/produto-variacao-tamanho/produto-variacao-tamanho.entity';
import {ProdutoVariacaoEstoque} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-estoque.entity';
import {ProdutoVariacaoPreco} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-preco.entity';
import {ProdutoVariacao} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao.entity';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {Secao} from '@app/abstraction/domain/entities/produtos/secao/secao.entity';
import {Subgrupo} from '@app/abstraction/domain/entities/produtos/subgrupo/subgrupo.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {SetorFacade} from '@app/abstraction/domain/facades/cadastros/setor/setor.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {AreaEstoqueFacade} from '@app/abstraction/domain/facades/estoque/area-estoque/area-estoque.facade';
import {NcmFacade} from '@app/abstraction/domain/facades/fiscal/ncm/ncm.facade';
import {FamiliaFacade} from '@app/abstraction/domain/facades/produtos/familia/familia.facade';
import {GrupoFacade} from '@app/abstraction/domain/facades/produtos/grupo/grupo.facade';
import {MarcaFacade} from '@app/abstraction/domain/facades/produtos/marca/marca.facade';
import {ProdutoVariacaoCorFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-cor/produto-variacao-cor.facade';
import {ProdutoVariacaoReferenciaFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-referencia/produto-variacao-referencia.facade';
import {ProdutoVariacaoTamanhoFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-tamanho/produto-variacao-tamanho.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {SecaoFacade} from '@app/abstraction/domain/facades/produtos/secao/secao.facade';
import {SubgrupoFacade} from '@app/abstraction/domain/facades/produtos/subgrupo/subgrupo.facade';
import {TabelaNutricionalFacade} from '@app/abstraction/domain/facades/produtos/tabela-nutricional/tabela-nutricional.facade';
import {UnidadeComercialFacade} from '@app/abstraction/domain/facades/produtos/unidade-comercial/unidade-comercial.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ProdutoForm} from '@app/abstraction/domain/forms/produtos/produto/produto.form';
import {FornecedorListagem} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-listagem.interface';
import {NcmListagem} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-listagem.interface';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {TipoCodigoBarra} from '@app/shared/enums/tipo-codigo-barra.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {FileService} from '@app/shared/services/file/file.service';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-produto-shared-form',
  templateUrl: './produto-shared-form.component.html',
  styleUrls: ['./produto-shared-form.component.scss'],
})
export class ProdutoSharedFormComponent extends FormBaseComponent implements
    OnInit, OnDestroy {
  @ViewChild('acoesProdutoVariacaoTemp', {static: true})
  acoesProdutoVariacaoTemp: TemplateRef<any>;
  @ViewChild('acoesProdutoVariacaoPrecoTemp', {static: true})
  acoesProdutoVariacaoPrecoTemp: TemplateRef<any>;
  @ViewChild('acoesProdutoVariacaoEstoqueTemp', {static: true})
  acoesProdutoVariacaoEstoqueTemp: TemplateRef<any>;
  @ViewChild('acoesProdutoVariacaoFotoTemp', {static: true})
  acoesProdutoVariacaoFotoTemp: TemplateRef<any>;
  @ViewChild('acoesFornecedorProdutoTemp', {static: true})
  acoesFornecedorProdutoTemp: TemplateRef<any>;
  @ViewChild('situacaoTemp', {static: true}) situacaoTemp: TemplateRef<any>;
  @ViewChild('porcentagemDecimalTemp', {static: true})
  porcentagemDecimalTemp: TemplateRef<any>;
  @ViewChild('quantidadeDecimalTemp', {static: true})
  quantidadeDecimalTemp: TemplateRef<any>;
  @ViewChild('valorDecimalTemp', {static: true})
  valorDecimalTemp: TemplateRef<any>;
  @ViewChild('empresaTemp', {static: true}) empresaTemp: TemplateRef<any>;
  @ViewChild('areaEstoqueTemp', {static: true})
  areaEstoqueTemp: TemplateRef<any>;
  @ViewChild('corDescricaoTemp', {static: true})
  corDescricaoTemp: TemplateRef<any>;
  @ViewChild('fotoTemp', {static: true}) fotoTemp: TemplateRef<any>;
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @ViewChild('variacaoTemp', {static: true}) variacaoTemp: TemplateRef<any>;
  @ViewChild('valorVendaTemp', {static: true}) valorVendaTemp: TemplateRef<any>;
  @ViewChild('ordemTemp', {static: true}) ordemTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;

  @Output() aoSalvar = new EventEmitter();

  produtoForm: ProdutoForm;
  colunasOcultasTabelaProdutoVariacao: TableColumn[];
  colunasOcultasTabelaProdutoVariacaoEstoque: TableColumn[];
  colunasOcultasTabelaProdutoVariacaoPreco: TableColumn[];
  colunasTabelaProdutoVariacao: TableColumn[];
  colunasTabelaVariacaoEstoque: TableColumn[];
  colunasTabelaFornecedorProduto: TableColumn[];
  colunasTabelaProdutoVariacaoFoto: TableColumn[];
  colunasTabelaProdutoVariacaoPreco: TableColumn[];
  colunasTabelaCodigosBarras: TableColumn[];
  colunasTabelaMovimentacoes: TableColumn[];
  sugestoesNcm: NcmListagem[];

  get mostrarDataCriacao() {
    return this.produtoForm?.mostrarDataCriacao;
  }
  get mostrarDataAlteracao() {
    return this.produtoForm?.mostrarDataAlteracao;
  }
  get listaEanGerados() {
    return this.produtoForm?.listaEanGerados;
  }
  get dadosTabelaVariacoesCodigosBarras() {
    return this.produtoForm?.dadosTabelaVariacoesCodigosBarras;
  }
  get empresaUnica() {
    return this.authFacade.empresaUnica;
  }
  get produtoTipoSimples() {
    return this.produtoForm?.produtoTipoSimples;
  }
  get produtoTipoVariacao() {
    return this.produtoForm?.produtoTipoVariacao;
  }
  get variacoes() {
    return this.produtoForm?.variacoes;
  }
  get produtoVariacaoPreco() {
    return this.produtoForm?.produtoVariacaoPreco;
  }
  get produtoVariacaoEstoque() {
    return this.produtoForm?.produtoVariacaoEstoque;
  }
  get produtoVariacaoFoto() {
    return this.produtoForm?.produtoVariacaoFoto;
  }
  get produtoVariacaoFornecedor() {
    return this.produtoForm?.produtoVariacaoFornecedor;
  }
  get produtoVariacaoDimensionamento() {
    return this.produtoForm?.produtoVariacaoDimensionamento;
  }
  get produtoVariacaoSimplesPreco() {
    return this.produtoForm?.produtoVariacaoSimplesPreco;
  }
  get produtoVariacaoSimplesEstoque() {
    return this.produtoForm?.produtoVariacaoSimplesEstoque;
  }
  get produtoVariacaoSimplesFornecedores() {
    return this.produtoForm?.produtoVariacaoSimplesFornecedores;
  }
  get produtoVariacaoSimplesDimensionamentoForm() {
    return this.produtoForm?.produtoVariacaoSimplesDimensionamentoForm;
  }
  get produtoPorPeso() {
    return this.produtoForm?.produtoPorPeso;
  }
  get produtoVariacaoSimplesFoto() {
    return this.produtoForm?.produtoVariacaoSimplesFoto;
  }
  get permitirTabelaNutricional() {
    return this.produtoForm?.permitirTabelaNutricional;
  }
  get permitirProdutoAssociado() {
    return this.produtoForm?.permitirProdutoAssociado;
  }
  get ncmCodigo() {
    return this.produtoForm?.ncmCodigo;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get secoes$() {
    return this.secaoFacade.secoes$;
  }
  get grupos$() {
    return this.grupoFacade.grupos$;
  }
  get subgrupos$() {
    return this.subgrupoFacade.subgrupos$;
  }
  get familias$() {
    return this.familiaFacade.familias$;
  }
  get marcas$() {
    return this.marcaFacade.marcas$;
  }
  get unidadesComerciais$() {
    return this.unidadeComercialFacade.unidadesComerciais$;
  }
  get tabelasNutricionais$() {
    return this.tabelaNutricionalFacade.tabelasNutricionais$;
  }
  get produtoVariacaoTamanhos$() {
    return this.tamanhoFacade.produtoVariacaoTamanhos$;
  }
  get produtoVariacaoCores$() {
    return this.corFacade.produtoVariacaoCores$;
  }
  get produtoVariacaoReferencias$() {
    return this.referenciaFacade.produtoVariacaoReferencias$;
  }
  get empresas() {
    return this.produtoForm?.empresas;
  }
  get empresasUsuarioLogado() {
    return this.authFacade.grupoLogado.empresas;
  }
  get areasEstoque() {
    return this.produtoForm?.areasEstoque;
  }
  get porcentagemDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposPorcentagem;
  }
  get quantidadeDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposQuantidade;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get porcentagemMask() {
    return this.mascaraService.decimal(this.porcentagemDecimais);
  }
  get quantidadeMask() {
    return this.mascaraService.decimal(this.quantidadeDecimais);
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get porcentagemPlaceholder() {
    return this.mascaraService.placeholder(this.porcentagemDecimais);
  }
  get quantidadePlaceholder() {
    return this.mascaraService.placeholder(this.quantidadeDecimais);
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  get maskCodigoBarras() {
    return this.parametroFacade.tipoCodigoBarras == TipoCodigoBarra.Code128 ?
        null :
        '00000000000000000000';
  }
  get eanRelacionados() {
    return this.produtoForm?.eanRelacionados;
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  get tituloTabPanelMovimentacoes() {
    return 'Últimas Movimentações';
  }
  get textoBotaoMovimentacoes() {
    return 'Exibir Todas Movimentações';
  }
  get produtoVariacaoSimplesFormGroup() {
    return this.produtoForm.produtoVariacaoSimplesFormGroup;
  }
  get setores$() {
    return this.setorFacade.setores$;
  }
  constructor(
      private loadingService: NgxUiLoaderService,
      private areaEstoqueFacade: AreaEstoqueFacade,
      private authFacade: AuthFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private empresaFacade: EmpresaFacade,
      private familiaFacade: FamiliaFacade,
      private fileService: FileService,
      private grupoFacade: GrupoFacade,
      private mascaraService: MascaraService,
      private ncmFacade: NcmFacade,
      private parametroFacade: ParametroFacade,
      private produtoFacade: ProdutoFacade,
      private corFacade: ProdutoVariacaoCorFacade,
      private referenciaFacade: ProdutoVariacaoReferenciaFacade,
      private tamanhoFacade: ProdutoVariacaoTamanhoFacade,
      private secaoFacade: SecaoFacade,
      private subgrupoFacade: SubgrupoFacade,
      private marcaFacade: MarcaFacade,
      private tabelaNutricionalFacade: TabelaNutricionalFacade,
      private unidadeComercialFacade: UnidadeComercialFacade,
      private fornecedorFacade: FornecedorFacade,
      private router: Router,
      private setorFacade: SetorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private buscarTributacaoPorNcm() {
    this.produtoForm.buscarTributacaoPorNcm();
  }
  private montarColunasTabelaMovimentacoes() {
    this.colunasTabelaMovimentacoes = [
      {
        field: 'operacao',
        name: 'Operação',
      },
      {
        field: 'areaEstoque',
        name: 'Área de Estoque',
      },
      {
        field: 'dataHora',
        name: 'Data',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.dataTemp
      },
      {
        field: 'quantidade',
        name: 'Quantidade',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'historico',
        name: 'Histórico',
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        width: 150,
      },
    ];
  }
  private montarColunasTabelaVariacao() {
    this.colunasTabelaProdutoVariacao = [
      {
        field: '',
        name: 'Variação',
        cellTemplate: this.variacaoTemp,
        width: 250,
      },
      {
        field: 'codigoBarras',
        name: 'Código de barras',
        cellClass: 'text-center',
        headerClass: 'text-center',
      },
      {
        field: 'sku',
        name: 'SKU',
      },
      {
        field: '',
        name: 'Valor de Venda',
        cellTemplate: this.valorVendaTemp,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesProdutoVariacaoTemp,
        width: 150,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacao();
  }
  private montarColunasTabelaVariacaoPreco() {
    this.colunasTabelaProdutoVariacaoPreco = [
      {
        field: 'empresaId',
        name: 'Empresa',
        cellTemplate: this.empresaTemp,
      },
      {
        field: 'valorCusto',
        name: 'Valor de custo (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorDecimalTemp,
      },
      {
        field: 'margem1Praticada',
        name: 'Valor de margem (%)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.porcentagemDecimalTemp,
      },
      {
        field: 'valorVenda1',
        name: 'Valor de venda 1 (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorDecimalTemp,
      },
      {
        field: 'valorVenda2',
        name: 'Valor de venda 2 (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorDecimalTemp,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesProdutoVariacaoPrecoTemp,

        width: 150,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacaoPreco();
  }
  private montarColunasTabelaVariacaoEstoque() {
    this.colunasTabelaVariacaoEstoque = [
      {
        field: 'areaEstoqueId',
        name: 'Área de Estoque',
        cellTemplate: this.areaEstoqueTemp,
      },
      {
        field: 'minimo',
        name: 'Estoque mínimo',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.quantidadeDecimalTemp,
      },
      {
        field: 'atual',
        name: 'Estoque atual',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.quantidadeDecimalTemp,
      },
      {
        field: 'maximo',
        name: 'Estoque máximo',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.quantidadeDecimalTemp,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesProdutoVariacaoEstoqueTemp,

        width: 150,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacaoEstoque();
  }
  private montarColunasTabelaProdutoVariacaoFoto() {
    this.colunasTabelaProdutoVariacaoFoto = [
      {
        field: 'ordem',
        name: 'Ordem',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.ordemTemp,
        width: 150,
      },
      {
        field: 'fotoBase64',
        name: 'Imagem',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.fotoTemp,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesProdutoVariacaoFotoTemp,

        width: 150,
      },
    ];
  }
  private montarColunasTabelaFornecedorProduto() {
    this.colunasTabelaFornecedorProduto = [
      {
        field: 'fornecedorNome',
        name: 'Fornecedor',
      },
      {
        field: 'fornecedorDocumento',
        name: 'Documento',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.documentoTemp,
      },
      {
        field: 'codigoReferencia',
        name: 'Código de Referência',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesFornecedorProdutoTemp,
        width: 150,
      },
    ];
  }
  private montarColunasOcultasTabelaProdutoVariacao() {
    this.colunasOcultasTabelaProdutoVariacao =
        this.colunasTabelaProdutoVariacao.filter((e) => e.hidden);
  }
  private montarColunasOcultasTabelaProdutoVariacaoPreco() {
    this.colunasOcultasTabelaProdutoVariacaoPreco =
        this.colunasTabelaProdutoVariacaoPreco.filter((e) => e.hidden);
  }
  private montarColunasOcultasTabelaProdutoVariacaoEstoque() {
    this.colunasOcultasTabelaProdutoVariacaoEstoque =
        this.colunasTabelaVariacaoEstoque.filter((e) => e.hidden);
  }
  private montarColunasTabelaCodigosBarras() {
    this.colunasTabelaCodigosBarras = [
      {
        field: '',
        name: 'Variação',
        cellTemplate: this.variacaoTemp,
        width: 250,
      },
      {
        field: 'codigoBarras',
        name: 'Código de barras',
        headerClass: 'text-center',
        cellClass: 'text-center',
      },
    ];
  }
  async buscarSugestoesNcms(event: any) {
    this.sugestoesNcm = await this.ncmFacade.buscarNcms({
      descricao: event.query,
      limite: 50,
    });
  }
  async gerarCodigoBarras() {
    this.saveLoading = true;
    if (this.produtoTipoSimples) {
      await this.produtoForm.gerarCodigoBarrasProdutoSimples().catch(() => {
        this.saveLoading = false;
      });
    } else {
      await this.produtoForm.gerarCodigoBarrasParaProdutoVariacao().catch(
          () => {
            this.saveLoading = false;
          });
    }
    this.saveLoading = false;
  }
  async gerarCodigosSku() {
    if (this.produtoForm.form.controls.descricaoReduzida.value) {
      const quantidade = 1 + this.produtoForm.variacoes.length;
      const descricao = this.produtoForm.form.controls.descricaoReduzida.value;
      const codigosSku =
          await this.produtoFacade.gerarCodigosSku(quantidade, descricao);

      if (this.produtoTipoSimples) {
        this.produtoForm.produtoVariacaoSimplesFormGroup.controls.sku.setValue(
            codigosSku.first());
      } else {
        this.produtoForm.produtoVariacaoFormArray.controls.forEach(
            (variacao: FormGroup, index: number) => {
              variacao.controls.sku.setValue(codigosSku[index]);
            });
      }
    }
  }
  abrirVariacaoModal(variacao?: ProdutoVariacao, index?: number) {
    this.produtoForm.abrirVariacaoModal(variacao, index);
  }
  abrirPrecoModal(preco?: ProdutoVariacaoPreco, index?: number) {
    this.produtoForm.abrirPrecoModal(preco, index);
  }
  abrirConfirmacaoExcluisaoFornecedorProduto(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.excluirFornecedorProduto(index);
      },
    });
  }
  abrirEstoqueModal(estoque?: ProdutoVariacaoEstoque, index?: number) {
    this.produtoForm.abrirEstoqueModal(estoque, index);
  }
  abrirReferenciaCadastroRapidoModal() {
    this.produtoForm.abrirReferenciaCadastroRapidoModal();
  }
  abrirCorCadastroRapidoModal() {
    this.produtoForm.abrirCorCadastroRapidoModal();
  }
  abrirTamanhoCadastroRapidoModal() {
    this.produtoForm.abrirTamanhoCadastroRapidoModal();
  }
  abrirSecaoCadastroRapidoModal() {
    this.produtoForm.abrirSecaoCadastroRapidoModal();
  }
  abrirGrupoCadastroRapidoModal() {
    this.produtoForm.abrirGrupoCadastroRapidoModal();
  }
  abrirSubgrupoCadastroRapidoModal() {
    this.produtoForm.abrirSubgrupoCadastroRapidoModal();
  }
  abrirFamiliaCadastroRapidoModal() {
    this.produtoForm.abrirFamiliaCadastroRapidoModal();
  }
  abrirMarcaCadastroRapidoModal() {
    this.produtoForm.abrirMarcaCadastroRapidoModal();
  }
  abrirUnidadeComercialCadastroRapidoModal() {
    this.produtoForm.abrirUnidadeComercialCadastroRapidoModal();
  }
  abrirTabelaNutricionalCadastroRapidoModal() {
    this.produtoForm.abrirTabelaNutricionalCadastroRapidoModal();
  }
  abrirNaturezaReceitaCadastroRapidoModal() {
    this.produtoForm.abrirNaturezaReceitaCadastroRapidoModal();
  }
  abrirConfirmacaoExcluisaoProdutoVariacao(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.excluirVariacao(index);
      },
    });
  }
  abrirConfirmacaoExclusaoProdutoVariacaoPreco(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.excluirPreco(index);
      },
    });
  }
  abrirConfirmacaoExcluisaoProdutoVariacaoEstoque(
      estoque: ProdutoVariacaoEstoque, index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.excluirEstoque(estoque, index);
      },
    });
  }
  abrirConfirmacaoExcluisaoProdutoVariacaoFoto(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.excluirFoto(index);
      },
    });
  }
  abrirConfirmacaoExcluisaoEanRelacionado(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.excluirEanRelacionado(index);
      },
    });
  }
  abrirCestBuscaRapidaModal() {
    this.produtoForm.abrirCestBuscaRapidaModal();
  }
  abrirFornecedorProdutoModal(
      fornecedorProduto?: FornecedorProduto, index?: number) {
    this.produtoForm.abrirFornecedorProdutoModal(fornecedorProduto, index);
  }
  abrirFornecedorBuscaRapidaModal() {
    this.produtoForm.abrirFornecedorBuscaRapidaModal();
  }
  abrirGeradorEanModal() {
    this.produtoForm.abrirGeradorEanModal();
  }
  adicionarEanRelacionadoComEnter(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.produtoForm.adicionarEanRelacionado();
    }
  }
  adicionarEanRelacionado() {
    this.produtoForm.adicionarEanRelacionado();
  }
  adicionarVariacoes() {
    this.produtoForm.adicionarVariacoes();
    this.gerarCodigosSku();
  }
  abrirConfirmacaoLimparVariacoes() {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja limpar as variações desse produto?',
      header: 'Atenção',
      acceptLabel: 'Limpar Variações',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.produtoForm.limparVariacoes();
      },
    });
  }
  abrirSetorCadastroRapidoModal() {
    this.produtoForm.abrirSetorCadastroRapidoModal();
  }
  buscarFoto(index: number) {
    return this.produtoForm.buscarFoto(index);
  }
  buscarValorVendaVariacao(index: number) {
    const variacao = this.variacoes.at(index);
    if (!variacao) return false;
    if (!variacao.precos?.length) return false;
    return variacao.precos?.first()?.valorVenda1;
  }
  calcularValores(campo: string) {
    this.produtoForm.calcularValores(campo);
  }
  criarProdutoForm() {
    this.produtoForm = new ProdutoForm(
        this.loadingService,
        this.areaEstoqueFacade,
        this.authFacade,
        this.empresaFacade,
        this.errorMessagesFacade,
        this.grupoFacade,
        this.ncmFacade,
        this.produtoFacade,
        this.secaoFacade,
        this.subgrupoFacade,
        this.familiaFacade,
        this.unidadeComercialFacade,
        this.fornecedorFacade,
        this.fileService,
        this.toastrService,
    );
  }
  fecharProdutoVariacaoModal() {
    this.produtoForm.fecharVariacaoModal();
  }
  fecharPrecoModal() {
    this.produtoForm.fecharPrecoModal();
  }
  fecharEstoqueModal() {
    this.produtoForm.fecharEstoqueModal();
  }
  fecharReferenciaCadastroRapidoModal() {
    this.produtoForm.fecharReferenciaCadastroRapidoModal();
  }
  fecharCorCadastroRapidoModal() {
    this.produtoForm.fecharCorCadastroRapidoModal();
  }
  fecharTamanhoCadastroRapidoModal() {
    this.produtoForm.fecharTamanhoCadastroRapidoModal();
  }
  fecharSecaoCadastroRapidoModal() {
    this.produtoForm.fecharSecaoCadastroRapidoModal();
  }
  fecharGrupoCadastroRapidoModal() {
    this.produtoForm.fecharGrupoCadastroRapidoModal();
  }
  fecharSubgrupoCadastroRapidoModal() {
    this.produtoForm.fecharSubgrupoCadastroRapidoModal();
  }
  fecharFamiliaCadastroRapidoModal() {
    this.produtoForm.fecharFamiliaCadastroRapidoModal();
  }
  fecharMarcaCadastroRapidoModal() {
    this.produtoForm.fecharMarcaCadastroRapidoModal();
  }
  fecharUnidadeComercialCadastroRapidoModal() {
    this.produtoForm.fecharUnidadeComercialCadastroRapidoModal();
  }
  fecharTabelaNutricionalCadastroRapidoModal() {
    this.produtoForm.fecharTabelaNutricionalCadastroRapidoModal();
  }
  fecharNaturezaReceitaCadastroRapidoModal() {
    this.produtoForm.fecharNaturezaReceitaCadastroRapidoModal();
  }
  fecharProdutoBuscaRapidaModal() {
    this.produtoForm.fecharProdutoBuscaRapidaModal();
  }
  fecharCestBuscaRapidaModal() {
    this.produtoForm.fecharCestBuscaRapidaModal();
  }
  fecharNcmBuscaRapidaModal() {
    this.produtoForm.fecharNcmBuscaRapidaModal();
  }
  fecharGeradorEanModal() {
    this.produtoForm.fecharGeradorEanModal();
  }
  fecharFornecedorProdutoModal() {
    this.produtoForm.fecharFornecedorProdutoModal();
  }
  fecharFornecedorBuscaRapidaModal() {
    this.produtoForm.fecharFornecedorBuscaRapidaModal();
  }
  fecharSetorCadastroRapidoModal() {
    this.produtoForm.fecharSetorCadastroRapidoModal();
  }
  iniciarForm() {
    this.criarProdutoForm();
    this.produtoForm.criarForm();
    this.produtoForm.monitorarCamposProduto();
    this.produtoForm.monitorarFormulario();
  }
  ngOnInit() {
    this.montarColunasTabelaVariacao();
    this.montarColunasTabelaVariacaoPreco();
    this.montarColunasTabelaVariacaoEstoque();
    this.montarColunasTabelaProdutoVariacaoFoto();
    this.montarColunasTabelaCodigosBarras();
    this.montarColunasTabelaFornecedorProduto();
    this.montarColunasTabelaMovimentacoes();
    this.montarDropdownProdutoVariacaoTipos();
    this.montarDropdownProdutoVendidoPor();
    this.montarDropdownProdutoCstsPorRegimeTributario(
        this.authFacade.empresaLogada.regimeTributario);
    this.montarDropdownProdutosServicos();
    this.montarDropdownProdutoOrigensString();
    this.montarDropdownProdutoTiposPisCofins();
    this.montarDropdownCstsPisCofinsEntrada();
    this.montarDropdownCstsPisCofinsSaida();
    this.montarDropdownProdutoCcsApuradas();
    this.montarDropdownCstsIpiEntrada();
    this.montarDropdownCstsIpiSaida();
  }
  ngOnDestroy() {
    this.produtoForm.unidadesComerciaisSubscription?.unsubscribe();
    this.produtoForm.secoesSubscription?.unsubscribe();
    this.produtoForm.gruposSubscription?.unsubscribe();
    this.produtoForm.subgruposSubscription?.unsubscribe();
    this.produtoForm.familiasSubscription?.unsubscribe();
    this.secaoFacade.limparListaSecoes();
    this.grupoFacade.limparListaGrupos();
    this.subgrupoFacade.limparListaSubgrupos();
    this.familiaFacade.limparListaFamilias();
    this.unidadeComercialFacade.limparListaUnidadesComerciais();
    this.referenciaFacade.limparListaProdutoVariacaoReferencias();
    this.corFacade.limparListaProdutoVariacaoCores();
    this.tamanhoFacade.limparListaProdutoVariacaoTamanhos();
    this.empresaFacade.limparListaEmpresas();
    this.tabelaNutricionalFacade.limparListaTabelasNutricionais();
    this.produtoFacade.limparListaProdutos();
    this.marcaFacade.limparListaMarcas();
  }
  preencherDescricaoReduzida(event: any) {
    this.produtoForm.preencherDescricaoReduzida(event);
    this.gerarCodigosSku();
  }
  recuperaDadosTab(event: any) {
    if (this.produtoForm.operacaoFormEditar) {
      if (event.originalEvent.currentTarget.textContent ===
          this.tituloTabPanelMovimentacoes) {
        this.produtoForm.buscarMovimentacoes();
      }
    }
  }
  reordenarProdutoVariacaoFoto() {
    this.produtoForm.reordenarProdutoVariacaoFoto();
  }
  salvarVariacao() {
    this.produtoForm.salvarVariacao();
  }
  salvarPreco() {
    this.produtoForm.salvarPreco();
  }
  salvarEstoque() {
    this.produtoForm.salvarEstoque();
  }
  salvarFornecedorProduto() {
    this.produtoForm.salvarFornecedorProduto();
  }
  salvarProdutoVariacaoFoto(evento: any) {
    this.produtoForm.salvarFoto(evento);
  }
  salvarProdutoAoGerarCodigoBarras() {
    this.aoSalvar.emit();
  }
  setarFamiliaCadastrada(familia: Familia) {
    this.produtoForm.setarFamiliaCadastrada(familia);
  }
  setarMarcaCadastrada(marca: Marca) {
    this.produtoForm.setarMarcaCadastrada(marca);
  }
  setarGrupoCadastrado(grupo: Grupo) {
    this.produtoForm.setarGrupoCadastrado(grupo);
  }
  setarProdutoSelecionado(produtoSelecionado: ProdutoSimplificadoListagem[]) {
    this.produtoForm.setarProdutoSelecionado(produtoSelecionado);
  }
  setarReferenciaCadastrada(referencia: ProdutoVariacaoReferencia) {
    this.produtoForm.setarReferenciaCadastrada(referencia);
  }
  setarProdutoVariacaoCorCadastrada(cor: ProdutoVariacaoCor) {
    this.produtoForm.setarCorCadastrada(cor);
  }
  setarTamanhoCadastrada(tamanho: ProdutoVariacaoTamanho) {
    this.produtoForm.setarTamanhoCadastrada(tamanho);
  }
  setarSecaoCadastrada(secao: Secao) {
    this.produtoForm.setarSecaoCadastrada(secao);
  }
  setarSubGrupoCadastrado(subGrupo: Subgrupo) {
    this.produtoForm.setarSubGrupoCadastrado(subGrupo);
  }
  setarCestSelecionado(cestSelecionado: Cest) {
    this.produtoForm.setarCestSelecionado(cestSelecionado);
  }
  setarNcmSelecionado(ncmSelecionado: Ncm) {
    this.produtoForm.setarNcmSelecionado(ncmSelecionado);
    this.buscarTributacaoPorNcm();
  }
  setarFornecedorSelecionado(fornecedor: FornecedorListagem) {
    this.produtoForm.setarFornecedorSelecionado(fornecedor);
  }
  preencherObjeto() {
    const produto = Produto.from(this.produtoForm.form.value);
    produto.toAPI(this.areaEstoqueFacade.areasEstoque);
    this.produtoForm.preencherCodigosBarras(produto);
    return produto;
  }
  verificarPrimeiraImagem(index: number) {
    const form = this.produtoForm.buscarFormProdutoSimplesOuVariacao();
    const fotos = form.controls.fotos.value;
    return index == fotos.first().ordem;
  }
  redirecionarParaMovimentacoes() {
    this.router.navigate(['/movimentacao']);
  }
}
