<p-tabView *ngIf="impressoraForm && impressoraForm?.form" [scrollable]="true">
  <p-tabPanel header="Dados do Impressora">
    <div class="formgrid grid">
      <!-- #region NOME -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Descrição" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [control]="impressoraForm.form.controls?.descricao"
          [maxlength]="60"
          [lengthOnlyNumber]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region TIPO DE CONEXÃO-->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Tipo de Conexão"></app-custom-label>
        <app-dropdown-input
          [options]="impressorasTipoConexao"
          [control]="impressoraForm.form.controls.tipoConexao"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region URL -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          [texto]="tipoConexaoRede ? 'URL' : 'Nome da Impressora'"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="impressoraForm.form.controls?.url"
          [maxlength]="60"
          [lengthOnlyNumber]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region PORTA -->
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6"
        *ngIf="tipoConexaoRede"
      >
        <app-custom-label texto="Porta" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [control]="impressoraForm.form.controls?.porta"
          [maxlength]="60"
          [lengthOnlyNumber]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region EMPRESAS -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Empresa" [obrigatorio]="true"></app-custom-label>
        <app-dropdown-input
          optionLabel="nomeFantasia"
          optionValue="id"
          [options]="empresas"
          [control]="impressoraForm.form.controls?.empresaId"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region ATIVO -->
      <div
        class="col-6 xl:col-2 md:col-3"
        [ngClass]="{
          'mt-0 ': !tipoConexaoRede,
          'mt-0 sm:mt-5': tipoConexaoRede
        }"
      >
        <app-checkbox-input
          label="Ativo"
          controlName="ativo"
          [control]="impressoraForm?.form.controls.ativo"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
  </p-tabPanel>
</p-tabView>
