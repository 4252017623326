import {Component, OnInit} from '@angular/core';
import {Impressora} from '@app/abstraction/domain/entities/cadastros/impressora/impressora.entity';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ImpressoraForm} from '@app/abstraction/domain/forms/cadastros/impressora.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-impressora-shared-form',
  templateUrl: './impressora-shared-form.component.html',
  styleUrls: ['./impressora-shared-form.component.scss']
})
export class ImpressoraSharedFormComponent extends FormBaseComponent implements
    OnInit {
  impressoraForm: ImpressoraForm;

  get tipoConexaoRede() {
    return this.impressoraForm.tipoConexaoRede;
  }
  get empresas() {
    return this.authFacade.grupoLogado.empresas;
  }
  constructor(
      private authFacade: AuthFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  preencherObjeto() {
    const impressora = Impressora.from(this.impressoraForm.form.value);
    return impressora;
  }
  criarForm() {
    this.impressoraForm = new ImpressoraForm(this.errorMessagesFacade);
    this.impressoraForm.criarForm();
    this.impressoraForm.monitorarTipoConexao();
  }
  ngOnInit() {
    this.criarForm();
    this.montarDropdownImpressoraTipoConexao();
  }
}
