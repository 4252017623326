<router-outlet></router-outlet>
<p-scrollTop></p-scrollTop>
<ngx-ui-loader></ngx-ui-loader>
<ngx-guided-tour
  backText="Voltar"
  nextText="Próximo"
  skipText="Pular"
  doneText="Finalizar"
></ngx-guided-tour>

<!-- <div class="center-image" *ngIf="mostrarLogo">
  <img
    src="assets/imagens/g3-erp-logo-light.png"
    alt="logo"
    width="300"
    height="100"
  />
</div> -->

<ngx-loading-bar height="4px" [includeSpinner]="false"></ngx-loading-bar>
<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  ref="router"
></ngx-loading-bar>
<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  ref="http"
></ngx-loading-bar>
