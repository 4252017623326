import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {ImpressoraService} from '@app/abstraction/data/services/cadastros/impressora.service';
import {ImpressoraStore} from '@app/abstraction/data/stores/cadastros/impressora.store';
import {Impressora} from '@app/abstraction/domain/entities/cadastros/impressora/impressora.entity';
import {ImpressoraFiltro} from '@app/abstraction/domain/interfaces/cadastros/impressora/impressora-filtro.interface';

@Injectable({
  providedIn: 'root',
})
export class ImpressoraFacade {
  get impressoras() {
    return this.store.impressoras;
  }
  get impressoras$() {
    return this.store.impressoras$;
  }
  constructor(
      private service: ImpressoraService,
      private store: ImpressoraStore,
  ) {}
  async buscarImpressoras(filtro?: ImpressoraFiltro) {
    const impressoras = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(impressoras);
    return impressoras;
  }
  async adicionar(impressora: Impressora) {
    const novoImpressora = await this.service.adicionar(impressora);
    if (novoImpressora) {
      this.store.adicionar(novoImpressora.toListagem());
    }
    return novoImpressora;
  }
  async atualizarSituacao(id: string) {
    const impressora = this.impressoras.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !impressora.ativo);
    impressora.ativo = status;
    return status;
  }
  alterar(impressora: Impressora, id: string) {
    return this.service.alterar(impressora, id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaImpressoras() {
    this.store.impressoras = null;
  }
}
