import {Component, OnInit} from '@angular/core';
import {Impressora} from '@app/abstraction/domain/entities/cadastros/impressora/impressora.entity';
import {Setor} from '@app/abstraction/domain/entities/cadastros/setor/setor.entity';
import {ImpressoraFacade} from '@app/abstraction/domain/facades/cadastros/impressora/impressora.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {SetorForm} from '@app/abstraction/domain/forms/cadastros/setor.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';


@Component({
  selector: 'app-setor-shared-form',
  templateUrl: './setor-shared-form.component.html',
  styleUrls: ['./setor-shared-form.component.scss']
})
export class SetorSharedFormComponent extends FormBaseComponent implements
    OnInit {
  setorForm: SetorForm;

  get impressoras() {
    return this.impressoraFacade.impressoras;
  }
  get empresas() {
    return this.authFacade.grupoLogado.empresas;
  }
  constructor(
      private authFacade: AuthFacade,
      private impressoraFacade: ImpressoraFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  abrirImpressoraCadastroRapidoModal() {
    this.setorForm.abrirImpressoraCadastroRapidoModal();
  }
  fecharImpressoraCadastroRapidoModal() {
    this.setorForm.fecharImpressoraCadastroRapidoModal();
  }
  preencherObjeto() {
    const setor = Setor.from(this.setorForm.form.value);
    return setor;
  }
  criarForm() {
    this.setorForm = new SetorForm(this.errorMessagesFacade);
    this.setorForm.criarForm();
  }
  ngOnInit() {
    this.criarForm();
  }
  setarImpressoraCadastrada(impressora: Impressora) {
    this.setorForm.form.controls.impressoraId.setValue(impressora.id);
  }
}
