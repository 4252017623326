import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {ClienteService} from '@app/abstraction/data/services/cadastros/cliente.service';
import {ClienteStore} from '@app/abstraction/data/stores/cadastros/cliente.store';
import {Cliente} from '@app/abstraction/domain/entities/cadastros/cliente/cliente.entity';
import {ClienteFiltro} from '@app/abstraction/domain/interfaces/cadastros/cliente/cliente-filtro.interface';
import {FileService} from '@app/shared/services/file/file.service';

@Injectable({providedIn: 'root'})
export class ClienteFacade {
  get clientes() {
    return this.store.clientes;
  }
  get clientes$() {
    return this.store.clientes$;
  }
  constructor(
      private service: ClienteService,
      private store: ClienteStore,
      private fileService: FileService,
  ) {}
  async atualizarSituacao(id: string) {
    const cliente = this.clientes.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !cliente.ativo);
    cliente.ativo = status;
    return status;
  }
  async buscarClientes(filtro?: ClienteFiltro) {
    const clientes = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(clientes);
    return clientes;
  }
  async downloadPlanilha() {
    const planilha = await this.service.downloadPlanilha();
    this.fileService.salvarArquivo(planilha, 'clientes-planilha-modelo.xlsx');
  }
  async adicionar(cliente: Cliente) {
    const novoCliente = await this.service.adicionar(cliente);
    if (novoCliente) {
      this.store.adicionar(novoCliente.toListagem());
    }
    return novoCliente;
  }
  async buscarPedidos(id: string) {
    var pedidos = await this.service.buscarPedidos(id);
    return pedidos;
  }
  async buscarRegistrosFinanceiros(id: string) {
    var registrosFinanceiros =
        await this.service.buscarRegistrosFinanceiros(id);
    return registrosFinanceiros;
  }
  importarPlanilha(fileBase64: string) {
    return this.service.importarPlanilha(fileBase64);
  }
  alterar(cliente: Cliente, id: string) {
    return this.service.alterar(cliente, id);
  }
  excluir(id: string) {
    return this.service.excluir(id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  buscarPorDocumento(documento: string) {
    return this.service.buscarPorDocumento(documento);
  }
  buscarTotalClientes() {
    return this.service.buscarTotalClientes();
  }
  limparListaClientes() {
    this.store.clientes = null;
  }
}
