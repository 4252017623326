import {Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {GuidedTour, GuidedTourService} from 'ngx-guided-tour';
import {Menu} from 'primeng/menu';

import {TextInputNgModelComponent} from '../ngmodel-inputs/text-input-ngmodel/text-input-ngmodel.component';

import {MenuTipo} from './enums/menu-tipo.enum';
import {MenuTopOpcoes} from './interfaces/menu-top-options.interface';

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss'],
})
export class MenuTopComponent implements OnInit {
  @ContentChild('buscaAvancada') buscaAvancada: TemplateRef<ElementRef>;
  @ContentChild('dropdownEmpresas') dropdownEmpresas: TemplateRef<ElementRef>;
  @ViewChild('inputPesquisa')
  set contentInputPesquisa(inputPesquisa: TextInputNgModelComponent) {
    if (inputPesquisa) {
      this.inputPesquisa = inputPesquisa;
      this.inputPesquisa.contentInput.nativeElement.select();
    }
  }
  inputPesquisa: TextInputNgModelComponent;
  @ViewChild('maisOpcoes')
  set contentMaisOpcoes(maisOpcoes: Menu) {
    if (maisOpcoes) {
      this.maisOpcoes = maisOpcoes;
    }
  }
  maisOpcoes: Menu;
  @Input() mostrarSpinner: boolean;
  @Input() linkYouTube: string;
  @Input() opcoes: MenuTopOpcoes;
  @Input() filtro: any;
  @Input() value: string = '';
  @Input() tour: GuidedTour;
  @Output() aoFiltrar = new EventEmitter<any>();
  @Output() aoSalvar = new EventEmitter();
  @Output() aoEnviar = new EventEmitter();
  @Output() mostrarSpinnerChange = new EventEmitter<boolean>();
  @Output() aoLimparCampos = new EventEmitter();
  @Output() aoClicarBuscar = new EventEmitter();
  @Output() aoClicarVisualizar = new EventEmitter();
  displayBuscaAvancada = false;
  linkYouTubeSanitized: any;

  get tipoFiltro() {
    return this.opcoes?.tipo === MenuTipo.Filtro;
  }
  get tipoCadastro() {
    return this.opcoes?.tipo === MenuTipo.Cadastro;
  }
  get filtrosCount() {
    if (!this.filtro) return null;
    const keys = Object.keys(this.filtro);
    const map = keys.map((key): any => {
                      if (this.filtro[key] !== undefined &&
                          this.filtro[key] !== null && this.filtro[key] !== '')
                        return key;
                    }).filter(f => f !== undefined && f !== null);
    if (!map.length) return null;
    return map.length.toString();
  }
  constructor(
      private sanitizer: DomSanitizer,
      private guidedTourService: GuidedTourService,
  ) {}
  ngOnInit(): void {
    this.linkYouTubeSanitized =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.linkYouTube);
  }
  mostrarBuscarAvancada() {
    this.displayBuscaAvancada = !this.displayBuscaAvancada;
    let div = document.getElementById('busca-avancada');
    if (this.displayBuscaAvancada) {
      div.classList.add('show');
    } else {
      div.classList.remove('show');
    }
  }
  filtrar(event?: any) {
    this.aoFiltrar.emit(event);
  }
  salvarClicado() {
    this.aoSalvar.emit();
  }
  visualizarClicado() {
    this.aoClicarVisualizar.emit();
  }
  mostrarMaisOpcoes(event: Event) {
    this.maisOpcoes.toggle(event);
  }
  limparCampos() {
    this.limparCampoPesquisa();
    this.aoLimparCampos.emit();
  }
  limparCampoPesquisa() {
    this.inputPesquisa.contentInput.nativeElement.value = '';
  }
  buscar() {
    this.mostrarBuscarAvancada();
    this.aoClicarBuscar.emit();
  }
  public startTour(): void {
    this.guidedTourService.startTour(this.tour);
  }
}
