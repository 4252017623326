import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/o-auth/o-auth.facade';
import {AppRoutingModule} from '@app/app-routing.module';
import {AppLayoutModule} from '@app/modules/layout/app.layout.module';
import {LOADING_BAR_CONFIG, LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {OAuthModule} from 'angular-oauth2-oidc';
import {GuidedTourModule} from 'ngx-guided-tour';
import {ToastrModule} from 'ngx-toastr';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';

import {DateSerializationInterceptor} from './interceptors/date-serialization.interceptor';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {HttpRequestInterceptor} from './interceptors/http-request.interceptor';
import {AnalyticsService} from './services/analytics.service';

registerLocaleData(localePt);

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    OAuthModule.forRoot(),
    GuidedTourModule.forRoot(),
    AppLayoutModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true,
      progressBar: true,
    }),
    NgxUiLoaderModule.forRoot(<NgxUiLoaderConfig>{
      bgsColor: 'red',
      bgsOpacity: 0.5,
      bgsPosition: 'bottom-right',
      bgsSize: 60,
      bgsType: 'ball-spin-clockwise',
      blur: 2,
      delay: 0.1,
      fastFadeOut: true,
      fgsColor: '#fe8000',
      fgsPosition: 'center-center',
      fgsSize: 60,
      fgsType: 'three-bounce',
      gap: 24,
      logoPosition: 'center-center',
      logoSize: 80,
      logoUrl: 'assets/imagens/g3-logo-light.png',
      masterLoaderId: 'master',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(40, 40, 40, 0.8)',
      pbColor: '#fe8000',
      pbDirection: 'ltr',
      pbThickness: 3,
      hasProgressBar: true,
      text: '',
      textColor: '#FFFFFF',
      textPosition: 'center-center',
      maxTime: -1,
      minTime: 300
    }),
  ],
  exports: [
    GuidedTourModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgxUiLoaderModule,
    AppLayoutModule,
  ],
  providers: [
    Title,
    ConfirmationService,
    MessageService,
    DialogService,
    {provide: LOADING_BAR_CONFIG, useValue: {latencyThreshold: 100}},
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateSerializationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [OAuthFacade],
    },
    {
      provide: ErrorHandler,
      useClass: AnalyticsService,
      deps: [AuthFacade, HttpClient],
    },
  ],
})
export class CoreModule {
}
