import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Impressora} from '@app/abstraction/domain/entities/cadastros/impressora/impressora.entity';
import {ImpressoraFiltro} from '@app/abstraction/domain/interfaces/cadastros/impressora/impressora-filtro.interface';
import {ImpressoraListagem} from '@app/abstraction/domain/interfaces/cadastros/impressora/impressora-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImpressoraService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  adicionar(dados: Impressora) {
    const response$ =
        this.client.post<Impressora>(API.CADASTRO_IMPRESSORA, dados)
            .pipe(map(
                (json: Impressora) => (json ? Impressora.from(json) : null)));
    return lastValueFrom(response$);
  }
  alterar(dados: Impressora, id: string) {
    const response$ =
        this.client.put<Impressora>(`${API.CADASTRO_IMPRESSORA}/${id}`, dados)
            .pipe(map(
                (json: Impressora) => (json ? Impressora.from(json) : null)));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.CADASTRO_IMPRESSORA}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Impressora>(`${API.CADASTRO_IMPRESSORA}/${id}`)
            .pipe(map(
                (json: Impressora) => (json ? Impressora.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ImpressoraFiltro) {
    const response$ = this.client.get<ImpressoraListagem[]>(
        this.geradorQueryString.gerar(`${API.CADASTRO_IMPRESSORA}`, filtro));
    return lastValueFrom(response$);
  }
}
