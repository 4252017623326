
export class LancamentoCentroCusto {
  id: string;
  centroCustoId: string;
  valor: number;
  porcentagem: number;

  static from(json: any = {}) {
    const lancamentoCentroCusto = new LancamentoCentroCusto();
    lancamentoCentroCusto.id = json.id;
    lancamentoCentroCusto.centroCustoId = json.centroCustoId;
    lancamentoCentroCusto.valor = json.valor ?? 0;
    lancamentoCentroCusto.porcentagem = json.porcentagem ?? 0;
    return lancamentoCentroCusto;
  }
}