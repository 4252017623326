import {environment} from 'environments/environment';

export class API {
  static readonly ERP_BASE_URL: string = environment.apiBaseUrl;
  static readonly AUTH_BASE_URL: string = environment.authBaseUrl;
  static readonly FISCAL_BASE_URL: string = environment.fiscalBaseUrl;
  static readonly BASE_URL_MOCK: string = 'http://localhost:3000';

  /* #region Gerais */
  static readonly PERMISSAO: string = `permissao`;
  static readonly PRODUTO: string = `produto`;
  static readonly SEGUNDO_NIVEL: string = 'segundo-nivel';
  static readonly STATUS: string = `status`;
  static readonly TERCEIRO_NIVEL: string = 'terceiro-nivel';
  /* #endregion */

  // ERP

  /* #region Auth */
  static readonly AUTH: string = 'auth';
  static readonly AUTH_PRIMEIRO_ACESSO: string = 'auth/primeiro-acesso';
  /* #endregion */

  /* #region Cest */
  static readonly CEST: string = 'fiscal/cests';

  /* #endregion */

  /* #region Cliente */
  static readonly CADASTRO_CLIENTE: string = 'cadastros/clientes';
  static readonly CADASTRO_CLIENTE_DOCUMENTO: string =
      'cadastros/clientes/documento';
  static readonly CADASTRO_CLIENTE_QUANTIDADE: string =
      'cadastros/clientes/quantidade';
  static readonly CADASTRO_CLIENTE_IMPORTAR_PLANILHA: string =
      'cadastros/clientes/importar/planilha';
  static readonly CADASTRO_CLIENTE_DOWNLOAD_PLANILHA: string =
      'cadastros/clientes/download/planilha';

  /* #endregion */

  /* #region Impressora */
  static readonly CADASTRO_IMPRESSORA: string = 'produtos/impressoras';

  /* #endregion */

  /* #region Setor */
  static readonly CADASTRO_SETOR: string = 'produtos/setores';

  /* #endregion */

  /* #region Cliente Situacao */
  static readonly CLIENTE_SITUACAO: string = 'cadastros/cliente-situacoes';

  /* #endregion */

  /* #region Lançamento */
  static readonly FINANCEIRO_LANCAMENTO: string = 'financeiro/lancamento';
  static readonly FINANCEIRO_LANCAMENTO_PAGAMENTOS: string =
      'financeiro/lancamento/pagamentos';
  static readonly FINANCEIRO_LANCAMENTO_PARCELAS: string =
      'financeiro/lancamento/parcelas';
  static readonly FINANCEIRO_LANCAMENTO_PARCELAS_ESTORNAR: string =
      'financeiro/lancamento/parcelas/estornar';
  static readonly FINANCEIRO_LANCAMENTO_CONTAS_RECEBER_IMPORTAR_PLANILHA:
      string = 'financeiro/lancamento/importar/planilha-contas-receber';
  static readonly FINANCEIRO_LANCAMENTO_CONTAS_RECEBER_DOWNLOAD_PLANILHA:
      string = 'financeiro/lancamento/download/planilha-contas-receber';
  static readonly FINANCEIRO_LANCAMENTO_CONTAS_PAGAR_IMPORTAR_PLANILHA: string =
      'financeiro/lancamento/importar/planilha-contas-pagar';
  static readonly FINANCEIRO_LANCAMENTO_CONTAS_PAGAR_DOWNLOAD_PLANILHA: string =
      'financeiro/lancamento/download/planilha-contas-pagar';
  /* #endregion */

  /* #region Código Fiscal */
  static readonly CODIGO_FISCAL_MANAGER: string =
      'fiscal/codigos-fiscais/manager';
  /* #endregion */

  /* #region Empresa */
  static readonly CADASTRO_EMPRESA: string = 'cadastros/empresas';
  static readonly MANAGER_EMPRESAS: string = 'manager/empresas';
  static readonly MANAGER_APIKEY: string = 'manager/apikey';
  static readonly MANAGER_EMPRESAS_DISPONIBILIDADE: string =
      'manager/empresas/disponibilidade';

  /* #endregion */

  /* #region Endereco */
  static readonly ENDERECO_TIPOS: string = 'enderecos/tipos';

  /* #endregion */

  /* #region Estoque */
  static readonly ESTOQUE_ENTRAR: string = 'estoque/entrar';
  static readonly ESTOQUE_BAIXAR: string = 'estoque/baixar';
  static readonly ESTOQUE_TRANSFERIR: string = 'estoque/transferir';
  static readonly ESTOQUE_COPIAR: string = 'estoque/copiar';
  static readonly ESTOQUE_EXPORTAR: string = 'estoque/exportar';
  static readonly ESTOQUE_MOVIMENTACAO: string = 'estoque/movimentacao';
  static readonly ESTOQUE_AJUSTE_MANUAL: string = 'estoque/ajuste-manual';
  static readonly ESTOQUE_TRANSFERENCIA_ENTRE_ESTOQUES: string =
      'estoque/transferencias';
  static readonly ESTOQUE_POR_PRODUTO: string = 'estoque/produto';
  static readonly ESTOQUE_CANCELAR_TRANSFERENCIA: string =
      'estoque/cancelar-transferencia';
  /* #endregion */

  /* #region Área Estoque */
  static readonly ESTOQUE_AREAESTOQUE: string = 'estoque/areaestoque';
  /* #endregion */

  /* #region Área Estoque */
  static readonly ESTOQUE_TIPOAJUSTE: string = 'estoque/tipos-ajuste';
  /* #endregion */

  /* #region Familia */
  static readonly FAMILIA: string = 'familia';

  /* #endregion */

  /* #region Forma Pagamento */
  static readonly FINANCEIRO_FORMA_PAGAMENTO: string =
      'financeiro/formas-pagamento';

  /* #endregion */

  /* #region Forma Parcelamento */
  static readonly FINANCEIRO_FORMA_PARCELAMENTO: string =
      'financeiro/formas-parcelamento';

  /* #endregion */

  /* #region Plano Conta Grupo */
  static readonly FINANCEIRO_PLANO_CONTA_GRUPO: string =
      'financeiro/plano-conta-grupos';

  /* #endregion */

  /* #region Fornecedor */
  static readonly CADASTRO_FORNECEDOR: string = 'cadastros/fornecedores';
  static readonly CADASTRO_FORNECEDOR_DOCUMENTO: string =
      'cadastros/fornecedores/documento';

  /* #endregion */

  /* #region Funcionário */
  static readonly CADASTRO_FUNCIONARIO: string = 'cadastros/funcionarios';
  static readonly CADASTRO_FUNCIONARIO_SIMPLES: string =
      'cadastros/funcionarios/simples';
  static readonly CADASTRO_FUNCIONARIO_DOCUMENTO: string =
      'cadastros/funcionarios/documento';
  static readonly CADASTRO_FUNCIONARIO_USUARIO: string =
      'cadastros/funcionarios/usuario';
  static readonly CADASTRO_FUNCIONARIO_IMPORTAR_PLANILHA: string =
      'cadastros/funcionarios/importar/planilha';
  static readonly CADASTRO_FUNCIONARIO_DOWNLOAD_PLANILHA: string =
      'cadastros/funcionarios/download/planilha';

  /* #endregion */

  /* #region Grupo */
  static readonly GRUPO: string = 'grupo';

  /* #endregion */

  /* #region Grupo */
  static readonly MARCA: string = 'marca';

  /* #endregion */

  /* #region Natureza Receita */
  static readonly NATUREZA_RECEITA: string = 'naturezareceita';

  /* #endregion */

  /* #region Ncm */
  static readonly FISCAL_NCM: string = 'fiscal/ncms';
  /* #endregion */

  /* #region Nível Acesso */
  static readonly CADASTROS_PERFIL: string = 'cadastros/perfil';
  /* #endregion */

  /* #region Fiscal */
  static readonly FISCAL_REGRA_FISCAL: string = 'fiscal/regras-fiscais';
  /* #endregion */

  /* #region Manifestacao */
  static readonly FISCAL_MANIFESTACOES: string = 'fiscal/manifestacoes';
  static readonly FISCAL_MANIFESTACOES_CONSULTAS: string =
      'fiscal/manifestacoes/consultas';
  static readonly FISCAL_MANIFESTACOES_CONSULTAS_TEMPO_RESTANTE: string =
      'fiscal/manifestacoes/consultas/tempo-restante';
  /* #endregion */

  /* #region Certificado */
  static readonly CERTIFICADOS: string = 'certificados';
  static readonly FISCAL_EMPRESAS: string = 'fiscal/empresas';
  /* #endregion */

  /* #region Nota Fiscal Entrada */
  static readonly NOTA_FISCAL_ENTRADA: string = 'fiscal/notas-fiscais/entradas';
  static readonly NOTA_FISCAL_ENTRADA_RESUMOS: string =
      'fiscal/notas-fiscais/entradas/resumos';
  /* #endregion */

  /* #region Nota Fiscal Saida */
  static readonly NOTA_FISCAL_SAIDA: string = 'fiscal/notas-fiscais/saidas';

  /* #endregion */

  /* #region Notificação */

  static readonly NOTIFICACOES: string = 'manager/notificacoes';
  static readonly NOTIFICACOES_LIDO: string = 'manager/notificacoes/lido';

  /* #endregion */

  /* #region Operacao Fiscal Entrada */
  static readonly FISCAL_OPERACOES_ENTRADAS: string =
      'fiscal/operacoes/entradas';
  /* #endregion */

  /* #region Operacao Fiscal Saída */
  static readonly FISCAL_OPERACOES_SAIDAS: string = 'fiscal/operacoes/saidas';
  static readonly FISCAL_OPERACOES_PRE_VISUALIZACAO: string =
      'fiscal/operacoes/saidas/pre-visualizacao';
  /* #endregion */

  /* #region Operacao Fiscal */
  static readonly FISCAL_OPERACOES: string = 'fiscal/operacoes';

  /* #endregion */

  /* #region Parametros */
  static readonly CONFIGURACOES_PARAMETROS: string = 'configuracoes/parametros';
  /* #endregion */

  /* #region Permissao */
  static readonly PERMISSAO_MODULO: string = 'permissao/modulo';

  /* #endregion */

  /* #region Pedido */
  static readonly VENDAS_PEDIDOS: string = 'vendas/pedidos';
  /* #endregion */

  /* #region Relatório */
  static readonly RELATORIOS_VENDAS_PEDIDOS_FATURAMENTO_GERAL: string =
      'relatorios/vendas/pedidos/faturamento-geral';
  static readonly RELATORIOS_VENDAS_PEDIDOS_DETALHE_VENDA: string =
      'relatorios/vendas/pedidos/detalhe-venda';
  static readonly RELATORIOS_VENDAS_PEDIDOS_EVOLUCAO_VENDAS: string =
      'relatorios/vendas/pedidos/evolucao-vendas';
  static readonly RELATORIOS_VENDAS_PEDIDOS_VENDAS_VENDEDOR: string =
      'relatorios/vendas/pedidos/vendas-vendedor';
  static readonly RELATORIOS_VENDAS_POR_GRUPO: string =
      'relatorios/vendas/pedidos/vendas-por-grupo';
  static readonly RELATORIOS_VENDAS_POR_DIA_MES_ANO: string =
      'relatorios/vendas/pedidos/vendas-por-dia-mes-ano';
  static readonly RELATORIOS_VENDAS_POR_SECAO: string =
      'relatorios/vendas/pedidos/vendas-por-secao';
  static readonly RELATORIOS_VENDAS_POR_HORA: string =
      'relatorios/vendas/pedidos/vendas-por-hora';
  static readonly RELATORIOS_VENDAS_POR_EMPRESA: string =
      'relatorios/vendas/pedidos/vendas-por-empresa';
  static readonly RELATORIOS_VENDAS_POR_FORMA_PAGAMENTO: string =
      'relatorios/vendas/pedidos/vendas-por-forma-pagamento';
  static readonly RELATORIOS_PRODUTOS_TOP_PRODUTOS: string =
      'relatorios/produtos/top-produtos';
  static readonly RELATORIOS_PRODUTOS_SECAO_TOP_SECOES: string =
      'relatorios/produtos/secoes/top-secoes';
  static readonly RELATORIOS_PRODUTOS_LISTA_PRECOS: string =
      'relatorios/produtos/lista-precos';
  static readonly RELATORIOS_PRODUTOS_PRODUTOS_MAIS_VENDIDOS_ANO: string =
      'relatorios/produtos/produtos-mais-vendidos-ano';
  static readonly RELATORIOS_CADASTROS_TOP_CLIENTES: string =
      'relatorios/cadastros/clientes/top-clientes';
  static readonly RELATORIOS_CADASTROS_CLIENTE_ANIVERSARIANTE: string =
      'relatorios/cadastros/clientes/cliente-aniversariante';
  static readonly RELATORIOS_CADASTROS_CLIENTE_HISTORICO_VENDA: string =
      'relatorios/cadastros/clientes/cliente-historico-venda';
  static readonly RELATORIOS_CADASTROS_CLIENTE_INATIVO: string =
      'relatorios/cadastros/clientes/cliente-inativo';
  static readonly RELATORIOS_CADASTROS_CLIENTE_CONVENIOS: string =
      'relatorios/cadastros/clientes/convenios';
  static readonly RELATORIOS_CADASTROS_CLIENTE_CONVENIADAS: string =
      'relatorios/cadastros/clientes/conveniadas';
  static readonly RELATORIOS_DASHBOARD_TOTAIS_PEDIDOS_MES: string =
      'relatorios/dashboard/totais-pedidos-mes';
  static readonly RELATORIOS_DASHBOARD_TOTAIS_COMPRAS_MES: string =
      'relatorios/dashboard/totais-compra-mes';
  static readonly RELATORIOS_DASHBOARD_TOTAIS_GRAFICO_VENDA: string =
      'relatorios/dashboard/totais-grafico-vendas';
  static readonly RELATORIOS_DASHBOARD_TOTAIS_LANCAMENTOS_MES: string =
      'relatorios/dashboard/totais-lancamentos-mes';
  static readonly RELATORIOS_FINANCEIROS_TOTAIS_POR_PERIODO: string =
      'relatorios/financeiros/lancamentos/totais-por-periodo';
  static readonly RELATORIOS_FINANCEIROS_CONTAS_PAGAR: string =
      'relatorios/financeiros/lancamentos/contas-pagar';
  static readonly RELATORIOS_FINANCEIROS_CONTAS_RECEBER: string =
      'relatorios/financeiros/lancamentos/contas-receber';
  static readonly RELATORIOS_FINANCEIROS_FLUXO_CAIXA: string =
      'relatorios/financeiros/lancamentos/fluxo-caixa';
  static readonly RELATORIOS_FINANCEIROS_PLANOS_CONTA: string =
      'relatorios/financeiros/planos-conta';
  static readonly RELATORIOS_FINANCEIROS_DEBITOS_CONVENIO: string =
      'relatorios/financeiros/pedidos/debitos-convenio';
  static readonly RELATORIOS_FINANCEIROS_CENTRO_CUSTO: string =
      'relatorios/financeiros/centro-custo';
  static readonly RELATORIOS_ESTOQUE_GERAL: string =
      'relatorios/estoques/estoque-geral';
  static readonly RELATORIOS_ESTOQUE_NOTA_ENTRADA_SAIDA: string =
      'relatorios/estoques/entrada-saida';
  static readonly RELATORIOS_TOTAL_G3_PAG: string =
      'relatorios/dashboard/totais-g3pag';
  static readonly RELATORIOS_FISCAL_ENTRADAS: string =
      'relatorios/fiscal/entradas';
  static readonly RELATORIOS_FISCAL_ENTRADAS_TOTAIS: string =
      'relatorios/fiscal/entradas/totais';

  /* #endregion */

  /* #region Pessoa */
  static readonly PESSOA: string = 'pessoas';

  /* #endregion */

  /* #region Plano */
  static readonly MANAGER_PLANO: string = 'manager/plano';
  /* #endregion */

  /* #region Plano Conta */
  static readonly FINANCEIRO_PLANO_CONTA: string = 'financeiro/plano-contas';
  static readonly FINANCEIRO_PLANO_CONTA_PRIMEIRO_NIVEL: string =
      'financeiro/plano-contas/primeiro-nivel';
  static readonly FINANCEIRO_PLANO_CONTA_SEGUNDO_NIVEL: string =
      'financeiro/plano-contas/segundo-nivel';
  static readonly FINANCEIRO_PLANO_CONTA_TERCEIRO_NIVEL: string =
      'financeiro/plano-contas/terceiro-nivel';

  /* #endregion */

  /* #region Produto */
  static readonly PRODUTO_IMPORTAR_PLANILHA: string =
      'produto/importar/planilha';
  static readonly PRODUTO_DOWNLOAD_PLANILHA: string =
      'produto/download/planilha';
  static readonly PRODUTO_QUANTIDADE: string = 'produto/quantidade';
  static readonly PRODUTO_VARIACAO: string = 'produto/variacao';
  static readonly PRODUTO_PESADO_EAN_DISPONIVEL: string =
      'produto/pesado/ean/disponivel';
  static readonly PRODUTO_EAN_DISPONIVEL: string = 'produto/ean/disponivel';
  static readonly PRODUTO_SKU_DISPONIVEL: string = 'produto/sku/disponivel';

  /* #endregion */

  /* #region Produto Variacao Cor */
  static readonly PRODUTO_VARIACAO_COR: string = 'produtovariacaocor';

  /* #endregion */

  /* #region Produto Variacao Referência */
  static readonly PRODUTO_VARIACAO_REFERENCIA: string =
      'produtovariacaoreferencia';

  /* #endregion */

  /* #region Produto Variacao Tamanho */
  static readonly PRODUTO_VARIACAO_TAMANHO: string = 'produtovariacaotamanho';

  /* #endregion */

  /* #region Preço */
  static readonly PRECO_VENDA: string = 'preco/principal';
  static readonly PRECO_VENDA_2: string = 'preco/preco2';
  static readonly PRECO_COPIAR: string = 'preco/copiar';

  /* #endregion */

  /* #region Registro */
  static readonly REGISTRO_SIMPLES: string = 'registro/simples';

  /* #endregion */

  /* #region Services */
  static readonly MANAGER_SERVICES_CPF: string = 'manager/services/cpf';
  static readonly MANAGER_SERVICES_CNPJ: string = 'manager/services/cnpj';

  /* #endregion */

  /* #region Seção */
  static readonly SECAO: string = 'secao';

  /* #endregion */

  /* #region Subgrupo */
  static readonly SUBGRUPO: string = 'subgrupo';

  /* #endregion */

  /* #region Tabela Nutricional */
  static readonly TABELA_NUTRICIONAL: string = 'tabelanutricional';

  /* #endregion */

  /* #region Transportadora */
  static readonly CADASTRO_TRANSPORTADORA: string = 'cadastros/transportadoras';

  /* #endregion */

  /* #region Banco */
  static readonly FINANCEIRO_BANCO: string = 'financeiro/bancos';

  /* #endregion */

  /* #region Carteira */
  static readonly FINANCEIRO_CARTEIRA: string = 'financeiro/carteiras';

  /* #endregion */

  /* #region Boleto */
  static readonly FINANCEIRO_BOLETO: string = 'financeiro/boletos';

  /* #endregion */

  /* #region Remessas */
  static readonly FINANCEIRO_REMESSAS: string = 'financeiro/remessas';

  /* #endregion */

  /* #region Administradora */
  static readonly FINANCEIRO_ADMINISTRADORA: string =
      'financeiro/administradoras';

  /* #endregion */

  /* #region Bandeira */
  static readonly FINANCEIRO_BANDEIRA: string = 'financeiro/bandeiras';

  /* #endregion */

  /* #region ContaBancaria */
  static readonly FINANCEIRO_CONTA_BANCARIA: string =
      'financeiro/contas-bancarias';
  static readonly FINANCEIRO_CONTA_BANCARIA_TRANSFERENCIA: string =
      'financeiro/contas-bancarias/transferencias';
  /* #endregion */

  /* #region ExtratoBancario */
  static readonly FINANCEIRO_EXTRATO_BANCARIO: string =
      'financeiro/extratos-bancarios';
  /* #endregion */

  /* #region CentroCusto */
  static readonly FINANCEIRO_CENTRO_CUSTO: string = 'financeiro/centro-custos';

  /* #endregion */

  /* #region Unidade Comercial */
  static readonly UNIDADE_COMERCIAL: string = 'unidadecomercial';

  /* #endregion */

  /* #region USUARIO */
  static readonly USUARIO: string = 'usuario';
  static readonly USUARIO_CONVITE: string = 'usuario/convite';
  /* #endregion */

  /* #region  Tributação */
  static readonly FISCAL_TRIBUTACAO: string =
      `${this.FISCAL_BASE_URL}/tributacao`;
  /* #endregion */

  /* #region VERSAO */
  static readonly VERSAO: string = 'manager/versoes';
  /* #endregion */

  /* #region RELEASE NOTE */
  static readonly RELEASE_NOTE: string = 'manager/release-notes';
  /* #endregion */

  /* #region OFX */
  static readonly FINANCEIRO_OFX: string = 'financeiro/ofx';
  static readonly FINANCEIRO_CONCILIACAO: string = 'financeiro/conciliacao';
  /* #endregion */

  /* #region CONVENIOS */
  static readonly CADASTROS_CONVENIADAS: string = 'cadastros/conveniadas';
  static readonly CADASTROS_CONVENIOS: string = 'cadastros/convenios';
  /* #endregion */

  /* #region Pdv */
  static readonly CADASTRO_PDV: string = 'cadastros/pdvs';

  /* #endregion */
}
