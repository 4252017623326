import '@app/shared/extensions/array.extension';

import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {AcaoMassa} from '@app/shared/components/dumb/menu-acoes-massa/interfaces/acao-massa.interface';
import {MenuTopOpcoes} from '@app/shared/components/dumb/menu-top/interfaces/menu-top-options.interface';
import {Estado, Estados} from '@app/shared/constants/estados.constant';
import {MedidaCaseiraParteDecimalConst, MedidasCaseirasParteDecimal,} from '@app/shared/constants/medidas-caseira-parte-decimal.constant';
import {MedidaCaseiraUtilizadaConst, MedidasCaseirasUtilizadas,} from '@app/shared/constants/medidas-caseira-utilizada.constant';
import {PaisConst, Paises} from '@app/shared/constants/paises.constant';
import {ProdutoCcsApuradaConst, ProdutoCcsApuradas,} from '@app/shared/constants/produto-ccs-apuradas.constant';
import {ProdutoCstIpiConst, ProdutoCstsIpi,} from '@app/shared/constants/produto-csts-ipi.constant';
import {ProdutoCstPisCofinsConst, ProdutoCstsPisCofins,} from '@app/shared/constants/produto-csts-pis-cofins.constant';
import {ProdutoCstConst, ProdutoCsts,} from '@app/shared/constants/produto-csts.constant';
import {ProdutoOrigemConst, ProdutoOrigens,} from '@app/shared/constants/produto-origens.constant';
import {ProdutoTipoPisCofinsConst, ProdutoTiposPisCofins,} from '@app/shared/constants/produto-tipos-pis-cofins.constant';
import {ProdutoServicoConst, ProdutosServicos,} from '@app/shared/constants/produtos-servicos.constant';
import {TipoPagamentoNfeConst, TiposPagamentoNfe,} from '@app/shared/constants/tipos-pagamento-nfe.constant';
import {UnidadePorcaoConst, UnidadesPorcao,} from '@app/shared/constants/unidade-porcao.constant';
import {AdquirenteLabels} from '@app/shared/enums/adquirente.enum';
import {AtividadeTipoLabels} from '@app/shared/enums/atividade-tipo.enum';
import {BoletoConsultaPorDataLabels} from '@app/shared/enums/boleto-consulta-por-data.enum';
import {CertificadoTipoLabels} from '@app/shared/enums/certificado-tipo.enum';
import {ClienteTipoLabels} from '@app/shared/enums/cliente-tipo.enum';
import {CnabLabels} from '@app/shared/enums/cnab.enum';
import {CodigoNaturezaLabels} from '@app/shared/enums/codigo-natureza.enum';
import {CodigosManifestacao} from '@app/shared/enums/codigos-manifestacao.enum';
import {ConsultaDataPeriodoLabels} from '@app/shared/enums/consulta-data-tipo.enum';
import {ContaTipoLabels} from '@app/shared/enums/conta-tipo.enum';
import {ContextoLabels} from '@app/shared/enums/contexto.enum';
import {ControleEstoqueTipoOperacaoLabels} from '@app/shared/enums/controle-estoque-tipo-operacao.enum';
import {DebitoConvenioConsultaPorDataLabels} from '@app/shared/enums/debito-convenio-consulta-por-data.enum';
import {DocumentoFiscalLabels} from '@app/shared/enums/documento-fiscal.enum';
import {FormaPagamentoTipoOperacaoLabels} from '@app/shared/enums/forma-pagamento-tipo-operacao.enum';
import {FusoHorarioLabels} from '@app/shared/enums/fuso-horario.enum';
import {IeIndicadorLabels} from '@app/shared/enums/ie-indicador.enum';
import {ImpressoraTipoConexaoLabels} from '@app/shared/enums/impressora-tipo-conexao.enum';
import {IntervaloLancamentoTipoLabels} from '@app/shared/enums/intervalo-lancamento-tipo.enum';
import {MesAbreviado, MesLabels} from '@app/shared/enums/mes.enum';
import {ModalidadeBaseCalculoSTLabels} from '@app/shared/enums/modalidade-bc-st.enum';
import {ModalidadeBaseCalculoLabels} from '@app/shared/enums/modalidade-bc.enum';
import {ModeloDFeLabels} from '@app/shared/enums/modelo-dfe.enum';
import {ModoExibicaoEvolucaoVendasLabels} from '@app/shared/enums/modo-exibicao-evolucao-vendas.enum';
import {MoldeLabels} from '@app/shared/enums/molde.enum';
import {NFeConsultaPorDataLabels} from '@app/shared/enums/nfe-consulta-por-data.enum';
import {NFeDocumentoTipoLabels} from '@app/shared/enums/nfe-documento-tipo.enum';
import {NFeFinalidadeLabels} from '@app/shared/enums/nfe-finalidade.enum';
import {NFeModalidadeFreteLabels} from '@app/shared/enums/nfe-modalidade-frete.enum';
import {NFeEntradaStatusLabels, NFeSaidaStatusLabels,} from '@app/shared/enums/nfe-status.enum';
import {OperacaoAdministradoraLabels} from '@app/shared/enums/operacao-administradora.enum';
import {OperacaoNotaFiscalLabels} from '@app/shared/enums/operacao-nota-fiscal.enum';
import {OperacaoLabels} from '@app/shared/enums/operacao.enum';
import {ParcelamentoTipoLabels} from '@app/shared/enums/parcelamento-tipo.enum';
import {PedidoConsultaPorDataLabels} from '@app/shared/enums/pedido-consulta-por-data.enum';
import {PedidoSituacaoLabels} from '@app/shared/enums/pedido-situacao.enum';
import {PedidoTipoLabels} from '@app/shared/enums/pedido-tipo.enum';
import {PessoaTipo, PessoaTipoLabels,} from '@app/shared/enums/pessoa-tipo.enum';
import {ProdutoTipoLabels} from '@app/shared/enums/produto-tipo.enum';
import {ProdutoVendidoPorLabels} from '@app/shared/enums/produto-vendido-por.enum';
import {RegimeTributario, RegimeTributarioLabels,} from '@app/shared/enums/regimes-tributarios.enum';
import {RegraFiscalTipoOperacaoLabels} from '@app/shared/enums/regra-fiscal-tipo-operacao.enum';
import {SituacaoContaLabels} from '@app/shared/enums/situacao-conta.enum';
import {SituacaoLancamento, SituacaoLancamentoLabels,} from '@app/shared/enums/situacao-lancamento.enum';
import {TipoCodigoBarraLabels} from '@app/shared/enums/tipo-codigo-barra.enum';
import {TipoContaLabels} from '@app/shared/enums/tipo-conta.enum';
import {TipoEstoqueLabels} from '@app/shared/enums/tipo-estoque.enum';
import {TipoFuncionarioLabels} from '@app/shared/enums/tipo-funcionario.enum';
import {TipoPdvLabels} from '@app/shared/enums/tipo-pdv.enum';
import {TipoReleaseNoteLabels} from '@app/shared/enums/tipo-release-note.enum';
import {DropdownItem} from '@app/shared/interfaces/components/dropdown-item.interface';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

import {SituacaoDebitoConvenioLabels} from '../../enums/situacao-debito-convenio.enum';

@Component({template: ''})
export class BaseComponent {
  atividadeTipos: DropdownItem[] = [];
  tipoReleaseNotes: DropdownItem[] = [];
  tiposEstoque: DropdownItem[] = [];
  contextos: DropdownItem[] = [];
  certificadoTipos: DropdownItem[] = [];
  clienteTipos: DropdownItem[] = [];
  cnabs: DropdownItem[] = [];
  estadosIbge: DropdownItem[] = [];
  estadosUf: DropdownItem[] = [];
  ieIndicadores: DropdownItem[] = [];
  tipoCodigoBarras: DropdownItem[] = [];
  moldes: DropdownItem[] = [];
  medidasCaseirasParteDecimal: DropdownItem[] = [];
  medidasCaseirasUtilizada: DropdownItem[] = [];
  modalidadesBaseCalculo: DropdownItem[] = [];
  modalidadesBaseCalculoST: DropdownItem[] = [];
  modelosDFe: DropdownItem[] = [];
  nfeDocumentoTipos: DropdownItem[] = [];
  nfeFinalidades: DropdownItem[] = [];
  nfeModalidadesFrete: DropdownItem[] = [];
  tiposFuncionario: DropdownItem[] = [];
  consultaDataPeriodos: DropdownItem[] = [];
  receitasDespesas: DropdownItem[] = [];
  debitoConvenioConsultaPorData: DropdownItem[] = [];
  situacaoDebitoConvenio: DropdownItem[] = [];
  nfeConsultaPorData: DropdownItem[] = [];
  pedidoConsultaPorData: DropdownItem[] = [];
  boletoConsultaPorData: DropdownItem[] = [];
  controleEstoqueTipoOperacao: DropdownItem[] = [];
  situacoesLancamento: DropdownItem[] = [];
  nfeStatusEntrada: DropdownItem[] = [];
  nfeStatusSaida: DropdownItem[] = [];
  propriedadesOrdem: DropdownItem[] = [];
  numerico: DropdownItem[] = [];
  numericoString: DropdownItem[] = [];
  numericoRange: DropdownItem[] = [];
  opcoes: MenuTopOpcoes;
  paises: DropdownItem[] = [];
  pedidoSituacoes: DropdownItem[] = [];
  pedidoTipos: DropdownItem[] = [];
  pessoaTipos: DropdownItem[] = [];
  produtoCcsApuradas: DropdownItem[] = [];
  produtoCsts: DropdownItem[] = [];
  produtoCstsIpiEntrada: DropdownItem[] = [];
  produtoCstsIpiSaida: DropdownItem[] = [];
  produtoCstsPisCofins: DropdownItem[] = [];
  produtoCstsPisCofinsEntrada: DropdownItem[] = [];
  produtoCstsPisCofinsSaida: DropdownItem[] = [];
  produtoOrigens: DropdownItem[] = [];
  produtoTiposPisCofins: DropdownItem[] = [];
  produtoVariacaoTipos: DropdownItem[] = [];
  produtoVendidoPor: DropdownItem[] = [];
  produtosServicos: DropdownItem[] = [];
  regimesTributarios: DropdownItem[] = [];
  saveLoading: boolean;
  situacoesConta: DropdownItem[] = [];
  tiposConta: DropdownItem[] = [];
  intervaloLancamentoTipos: DropdownItem[] = [];
  parcelamentoTipos: DropdownItem[] = [];
  tiposOperacao: DropdownItem[] = [];
  operacoesAdministradora: DropdownItem[] = [];
  operacoesNotaFiscal: DropdownItem[] = [];
  operacoes: DropdownItem[] = [];
  tiposPagamentoNfe: DropdownItem[] = [];
  unidadesPorcao: DropdownItem[] = [];
  contaTipos: DropdownItem[] = [];
  codigoNaturezas: DropdownItem[] = [];
  meses: DropdownItem[] = [];
  documentoFiscal: DropdownItem[] = [];
  fusoHorarios: DropdownItem[] = [];
  tiposPdv: DropdownItem[] = [];
  adquirentes: DropdownItem[] = [];
  modoExibicao: DropdownItem[] = [];
  impressorasTipoConexao: DropdownItem[] = [];
  acoesMassa: AcaoMassa[];
  dataFormatoPt = 'dd/MM/yyyy';
  dataHoraFormatoPt = 'dd/MM/yyyy \'às\' HH:mm\'h\'';
  mascaraDocumento = '000.000.000-00||00.000.000/0000-00';
  mascaraCep = '00000-000';
  colunasOcultasSelecionadas = <any>[];
  direcoesFiltro: DropdownItem[] = [
    {texto: 'Ascendente', valor: 'asc'},
    {texto: 'Descendente', valor: 'desc'},
  ];
  situacaoCodigoManifetacao: DropdownItem[] = [
    {texto: 'Não Manifestadas', valor: 0},
    {texto: 'Dado Ciência', valor: CodigosManifestacao.DarCiencia},
    {texto: 'Confirmadas', valor: CodigosManifestacao.Confirmar},
    {texto: 'Desconhecidas', valor: CodigosManifestacao.Desconhecer},
    {
      texto: 'Operação não Realizada',
      valor: CodigosManifestacao.OperacaoNaoRealizada
    },
  ];
  situacoes: DropdownItem[] = [
    <DropdownItem>{
      texto: 'Ativo',
      valor: true,
    },
    <DropdownItem>{
      texto: 'Inativo',
      valor: false,
    },
  ];
  niveisPlanoContas: DropdownItem[] = [
    <DropdownItem>{
      texto: 'Nível 1',
      valor: 1,
    },
    <DropdownItem>{
      texto: 'Nível 2',
      valor: 2,
    },
    <DropdownItem>{
      texto: 'Nível 3',
      valor: 3,
    },
  ];
  booleano: DropdownItem[] = [
    <DropdownItem>{
      texto: 'Sim',
      valor: true,
    },
    <DropdownItem>{
      texto: 'Não',
      valor: false,
    },
  ];
  constructor(
      public toastrService: ToastrService,
      public errorMessagesFacade: ErrorMessageFacade,
  ) {}
  private setarCamposInvalidos(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormGroup) {
        this.setarCamposInvalidos(control);
      }
    });
  }
  formControl(name: string, formGroup: UntypedFormGroup) {
    return formGroup.get(name);
  }
  fileToBase64 = (file: Blob): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let result = reader.result.toString();
          const indexOf = 'base64,';
          const index = result.indexOf(indexOf);
          result = result.substring(index + indexOf.length);
          resolve(result);
        };
        reader.onerror = (error) => reject(error);
      });
  imagemParaBase64 = (file: Blob): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let result = reader.result.toString();
          resolve(result);
        };
        reader.onerror = (error) => reject(error);
      });
  montarDropdownContaTipos() {
    ContaTipoLabels.forEach((valor, chave) => {
      this.contaTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownCodigoNaturezas() {
    CodigoNaturezaLabels.forEach((valor, chave) => {
      this.codigoNaturezas.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownDocumentoFiscal() {
    DocumentoFiscalLabels.forEach((valor, chave) => {
      this.documentoFiscal.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownRegimeTributario() {
    RegimeTributarioLabels.forEach((valor, chave) => {
      this.regimesTributarios.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownModelosDFe() {
    ModeloDFeLabels.forEach((valor, chave) => {
      this.modelosDFe.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownClienteTipo() {
    ClienteTipoLabels.forEach((valor, chave) => {
      this.clienteTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownCnab() {
    CnabLabels.forEach((valor, chave) => {
      this.cnabs.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownCertificadoTipos() {
    CertificadoTipoLabels.forEach((valor, chave) => {
      this.certificadoTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownAtividadeTipo() {
    AtividadeTipoLabels.forEach((valor, chave) => {
      this.atividadeTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownImpressoraTipoConexao() {
    ImpressoraTipoConexaoLabels.forEach((valor, chave) => {
      this.impressorasTipoConexao.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownTiposConta() {
    TipoContaLabels.forEach((valor, chave) => {
      this.tiposConta.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownTipoReleaseNote() {
    TipoReleaseNoteLabels.forEach((valor, chave) => {
      this.tipoReleaseNotes.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: valor,
      });
    });
  }
  montarDropdownContexto() {
    ContextoLabels.forEach((valor, chave) => {
      this.contextos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: valor,
      });
    });
  }
  montarDropdownIntervaloLancamentoTipo() {
    IntervaloLancamentoTipoLabels.forEach((valor, chave) => {
      this.intervaloLancamentoTipos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownParcelamentoTipos() {
    ParcelamentoTipoLabels.forEach((valor, chave) => {
      this.parcelamentoTipos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownSituacoesConta() {
    SituacaoContaLabels.forEach((valor, chave) => {
      this.situacoesConta.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownTiposOperacaoRegraFiscal() {
    RegraFiscalTipoOperacaoLabels.forEach((valor, chave) => {
      this.tiposOperacao.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownOperacoesAdministradoras() {
    OperacaoAdministradoraLabels.forEach((valor, chave) => {
      this.operacoesAdministradora.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave.toString(),
      });
    });
  }
  montarDropdownOperacoesNotasFiscais() {
    OperacaoNotaFiscalLabels.forEach((valor, chave) => {
      this.operacoesNotaFiscal.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave.toString(),
      });
    });
  }
  montarDropdownOperacao() {
    OperacaoLabels.forEach((valor, chave) => {
      this.operacoes.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }

  montarDropdownTiposOperacaoFormaPagamento() {
    FormaPagamentoTipoOperacaoLabels.forEach((valor, chave) => {
      this.tiposOperacao.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownModalidadeBaseCalculo() {
    ModalidadeBaseCalculoLabels.forEach((valor, chave) => {
      this.modalidadesBaseCalculo.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownModalidadeBaseCalculoST() {
    ModalidadeBaseCalculoSTLabels.forEach((valor, chave) => {
      this.modalidadesBaseCalculoST.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownIeIndicador() {
    IeIndicadorLabels.forEach((valor, chave) => {
      this.ieIndicadores.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownTipoCodigoBarras() {
    TipoCodigoBarraLabels.forEach((valor, chave) => {
      this.tipoCodigoBarras.push(<DropdownItem>{
        texto: `${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownFusoHorarios() {
    FusoHorarioLabels.forEach((valor, chave) => {
      this.fusoHorarios.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }

  montarDropdownNumerico(quantidade: number) {
    const lista = Array.from(Array(quantidade)).map((_, i) => i + 1);
    lista.forEach((valor: any) => {
      this.numerico.push(<DropdownItem>{
        texto: valor,
        valor: valor,
      });
    });
  }
  montarDropdownNumericoString(quantidade: number) {
    const lista = Array.from(Array(quantidade)).map((_, i) => i + 1);
    lista.forEach((valor: any) => {
      this.numericoString.push(<DropdownItem>{
        texto: valor,
        valor: valor.toString(),
      });
    });
  }
  montarDropdownMes() {
    MesLabels.forEach((valor, chave) => {
      this.meses.push(<DropdownItem>{
        texto: `${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownNumericoComRange(
      numeroInicial: number, escopoNumerico: number) {
    const primeiroNumero = numeroInicial - escopoNumerico;
    const rangeArray = escopoNumerico * 2 + 1;

    const lista =
        Array.from(Array(rangeArray)).map((_, i) => i + primeiroNumero);
    lista.forEach((valor: any) => {
      this.numericoRange.push(<DropdownItem>{
        texto: valor,
        valor: valor,
      });
    });
  }
  montarDropdownPedidoTipo() {
    PedidoTipoLabels.forEach((valor, chave) => {
      this.pedidoTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownPedidoSituacao() {
    PedidoSituacaoLabels.forEach((valor, chave) => {
      this.pedidoSituacoes.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownPessoaTipo() {
    PessoaTipoLabels.forEach((valor, chave) => {
      this.pessoaTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownEstadosIbge() {
    Estados.sort((a, b) => a.ufDescricao.localeCompare(b.ufDescricao))
        .forEach((estado: Estado) => {
          this.estadosIbge.push(<DropdownItem>{
            texto: estado.ufDescricao,
            valor: estado.ufIbge,
          });
        });
  }
  montarDropdownMolde() {
    MoldeLabels.forEach((valor, chave) => {
      this.moldes.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdowModoExibicaoEvolucaoVendas() {
    ModoExibicaoEvolucaoVendasLabels.forEach((valor, chave) => {
      this.modoExibicao.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownTipoFuncionario() {
    TipoFuncionarioLabels.forEach((valor, chave) => {
      this.tiposFuncionario.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownTiposPagamentoNFeNumber() {
    TiposPagamentoNfe.forEach((tipoPagamento: TipoPagamentoNfeConst) => {
      this.tiposPagamentoNfe.push(<DropdownItem>{
        texto: `${tipoPagamento.codigo} - ${tipoPagamento.descricao}`,
        valor: tipoPagamento.codigo,
      });
    });
  }
  montarDropdownTiposPagamentoNFeString() {
    TiposPagamentoNfe.forEach((tipoPagamento: TipoPagamentoNfeConst) => {
      this.tiposPagamentoNfe.push(<DropdownItem>{
        texto: `${tipoPagamento.codigo} - ${tipoPagamento.descricao}`,
        valor: tipoPagamento.codigo.toString(),
      });
    });
  }
  montarDropdownEstadosUf() {
    Estados.sort((a, b) => a.ufDescricao.localeCompare(b.ufDescricao))
        .forEach((estado: Estado) => {
          this.estadosUf.push(<DropdownItem>{
            texto: estado.ufDescricao,
            valor: estado.ufSigla,
          });
        });
  }
  montarDropdownPaises() {
    Paises.orderBy((p: any) => p.paisDescricao).forEach((pais: PaisConst) => {
      this.paises.push(<DropdownItem>{
        texto: pais.paisDescricao,
        valor: pais.paisIbge,
      });
    });
  }
  montarDropdownProdutosServicos() {
    ProdutosServicos.forEach((produtoServico: ProdutoServicoConst) => {
      this.produtosServicos.push(<DropdownItem>{
        texto: `${produtoServico.codigo} - ${produtoServico.descricao}`,
        valor: produtoServico.codigo,
      });
    });
  }
  montarDropdownProdutoOrigensString() {
    ProdutoOrigens.forEach((produtoOrigem: ProdutoOrigemConst) => {
      this.produtoOrigens.push(<DropdownItem>{
        texto: `${produtoOrigem.codigo} - ${produtoOrigem.descricao}`,
        valor: produtoOrigem.codigo,
      });
    });
  }
  montarDropdownProdutoOrigensNumber() {
    ProdutoOrigens.forEach((produtoOrigem: ProdutoOrigemConst) => {
      this.produtoOrigens.push(<DropdownItem>{
        texto: `${produtoOrigem.codigo} - ${produtoOrigem.descricao}`,
        valor: Number(produtoOrigem.codigo),
      });
    });
  }
  montarDropdownProdutoCsts() {
    ProdutoCsts.forEach((produtoCst: ProdutoCstConst) => {
      this.produtoCsts.push(<DropdownItem>{
        texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
        valor: produtoCst.codigo,
      });
    });
  }
  montarDropdownProdutoCstsPorRegimeTributario(regimeTributario:
                                                   RegimeTributario) {
    if (regimeTributario === RegimeTributario.Mei)
      regimeTributario = RegimeTributario.SimplesNacional;
    ProdutoCsts
        .filter(
            (cst: ProdutoCstConst) => cst.regimeTributario === regimeTributario)
        .forEach((produtoCst: ProdutoCstConst) => {
          this.produtoCsts.push(<DropdownItem>{
            texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
            valor: produtoCst.codigo,
          });
        });
  }
  montarDropdownProdutoTiposPisCofins() {
    ProdutoTiposPisCofins.forEach((produtoCst: ProdutoTipoPisCofinsConst) => {
      this.produtoTiposPisCofins.push(<DropdownItem>{
        texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
        valor: produtoCst.codigo,
      });
    });
  }
  montarDropdownCstsPisCofins() {
    ProdutoCstsPisCofins.forEach((produtoCst: ProdutoCstPisCofinsConst) => {
      this.produtoCstsPisCofins.push(<DropdownItem>{
        texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
        valor: produtoCst.codigo,
      });
    });
  }
  montarDropdownCstsPisCofinsEntrada() {
    ProdutoCstsPisCofins
        .filter((produtoCst: ProdutoCstIpiConst) => produtoCst.tipoCst == 'E')
        .forEach((produtoCst: ProdutoCstPisCofinsConst) => {
          this.produtoCstsPisCofinsEntrada.push(<DropdownItem>{
            texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
            valor: produtoCst.codigo,
          });
        });
  }
  montarDropdownCstsPisCofinsSaida() {
    ProdutoCstsPisCofins
        .filter((produtoCst: ProdutoCstIpiConst) => produtoCst.tipoCst == 'S')
        .forEach((produtoCst: ProdutoCstPisCofinsConst) => {
          this.produtoCstsPisCofinsSaida.push(<DropdownItem>{
            texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
            valor: produtoCst.codigo,
          });
        });
  }
  montarDropdownProdutoCcsApuradas() {
    ProdutoCcsApuradas.forEach((produtoCst: ProdutoCcsApuradaConst) => {
      this.produtoCcsApuradas.push(<DropdownItem>{
        texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
        valor: produtoCst.codigo,
      });
    });
  }
  montarDropdownCstsIpiEntrada() {
    ProdutoCstsIpi
        .filter((produtoCst: ProdutoCstIpiConst) => produtoCst.tipoCst == 'E')
        .forEach((produtoCst: ProdutoCstIpiConst) => {
          this.produtoCstsIpiEntrada.push(<DropdownItem>{
            texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
            valor: produtoCst.codigo,
          });
        });
  }
  montarDropdownCstsIpiSaida() {
    ProdutoCstsIpi
        .filter((produtoCst: ProdutoCstIpiConst) => produtoCst.tipoCst == 'S')
        .forEach((produtoCst: ProdutoCstIpiConst) => {
          this.produtoCstsIpiSaida.push(<DropdownItem>{
            texto: `${produtoCst.codigo} - ${produtoCst.descricao}`,
            valor: produtoCst.codigo,
          });
        });
  }
  montarDropdownProdutoVariacaoTipos() {
    ProdutoTipoLabels.forEach((valor, chave) => {
      this.produtoVariacaoTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownProdutoVendidoPor() {
    ProdutoVendidoPorLabels.forEach((valor, chave) => {
      this.produtoVendidoPor.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownSituacaoDebitoConvenio() {
    SituacaoDebitoConvenioLabels.forEach((texto, valor) => {
      this.situacaoDebitoConvenio.push(<DropdownItem>{
        texto: `${valor} - ${texto}`,
        valor: valor,
      });
    });
  }
  montarDropdownDebitoConvenioConsultaPorData() {
    DebitoConvenioConsultaPorDataLabels.forEach((texto, valor) => {
      this.debitoConvenioConsultaPorData.push(<DropdownItem>{
        texto: `${valor} - ${texto}`,
        valor: valor,
      });
    });
  }
  montarDropdownUnidadePorcao() {
    UnidadesPorcao.forEach((unidadePorcao: UnidadePorcaoConst) => {
      this.unidadesPorcao.push(<DropdownItem>{
        texto: `${unidadePorcao.codigo} - ${unidadePorcao.descricao}`,
        valor: unidadePorcao.codigo,
      });
    });
  }
  montarDropdownMedidaCaseiraParteDecimal() {
    MedidasCaseirasParteDecimal.forEach(
        (medidaCaseira: MedidaCaseiraParteDecimalConst) => {
          this.medidasCaseirasParteDecimal.push(<DropdownItem>{
            texto: `${medidaCaseira.codigo} - ${medidaCaseira.descricao}`,
            valor: medidaCaseira.codigo,
          });
        });
  }
  montarDropdownMedidaCaseiraUtilizada() {
    MedidasCaseirasUtilizadas.forEach(
        (medidaCaseira: MedidaCaseiraUtilizadaConst) => {
          this.medidasCaseirasUtilizada.push(<DropdownItem>{
            texto: `${medidaCaseira.codigo} - ${medidaCaseira.descricao}`,
            valor: medidaCaseira.codigo,
          });
        });
  }
  montarDropdownNFeDocumentoTipos() {
    NFeDocumentoTipoLabels.forEach((valor, chave) => {
      this.nfeDocumentoTipos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownNFeFinalidades() {
    NFeFinalidadeLabels.forEach((valor, chave) => {
      this.nfeFinalidades.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownNFeModalidadesFrete() {
    NFeModalidadeFreteLabels.forEach((valor, chave) => {
      this.nfeModalidadesFrete.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownNFeConsultaPorData() {
    NFeConsultaPorDataLabels.forEach((valor, chave) => {
      this.nfeConsultaPorData.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownPedidoConsultaPorData() {
    PedidoConsultaPorDataLabels.forEach((valor, chave) => {
      this.pedidoConsultaPorData.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownBoletoConsultaPorData() {
    BoletoConsultaPorDataLabels.forEach((valor, chave) => {
      this.boletoConsultaPorData.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownConsultaDataPeriodos() {
    ConsultaDataPeriodoLabels.forEach((valor, chave) => {
      this.consultaDataPeriodos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownReceitasDespesas() {
    this.receitasDespesas = [
      <DropdownItem>{
        texto: 'RECEITAS',
        valor: false,
      },
      <DropdownItem>{
        texto: 'DESPESAS',
        valor: true,
      }
    ];
  }
  montarDropdownTipoEstoque() {
    TipoEstoqueLabels.forEach((valor, chave) => {
      this.tiposEstoque.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownControleEstoqueTipoOperacao() {
    ControleEstoqueTipoOperacaoLabels.forEach((valor, chave) => {
      this.controleEstoqueTipoOperacao.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownSituacaoLancamento(incluirVencido?: boolean) {
    SituacaoLancamentoLabels.forEach((valor, chave) => {
      var isVencido = chave == SituacaoLancamento.Vencido;
      if (!isVencido || incluirVencido) {
        this.situacoesLancamento.push(<DropdownItem>{
          texto: valor,
          valor: chave,
        });
      }
    });
  }
  montarDropdownNFeStatusEntrada() {
    NFeEntradaStatusLabels.forEach((valor, chave) => {
      this.nfeStatusEntrada.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownNFeStatusSaida() {
    NFeSaidaStatusLabels.forEach((valor, chave) => {
      this.nfeStatusSaida.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownPropriedadeOrdem(filtroLabels: any) {
    const keys = Object.keys(filtroLabels);
    this.propriedadesOrdem = keys.map((key) => {
      return <DropdownItem>{texto: filtroLabels[key], valor: key};
    });
  }
  montarDropdownTiposPdv() {
    TipoPdvLabels.forEach((valor, chave) => {
      this.tiposPdv.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownAdquirentes() {
    AdquirenteLabels.forEach((valor, chave) => {
      this.adquirentes.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  retornarCpfCnpjMask(pessoaTipo: PessoaTipo) {
    return pessoaTipo === PessoaTipo.Juridica ? '00.000.000/0000-00' :
                                                '000.000.000-00';
  }
  retornarMesAno(abreviarMes?: boolean): string {
    let mes: string;
    let data = moment();

    mes = abreviarMes ? Object.values(MesAbreviado)[data.month()] :
                        Object.keys(MesAbreviado)[data.month()];

    let ano = data.year();
    return `${mes} ${ano}`;
  }
  retornarDiasInicioFimMes(mes?: number, ano?: number) {
    let data = moment();
    let y = ano != null ? ano : data.year();
    let m = mes != null ? mes : data.month();

    let primeiroDia = moment(new Date(y, m, 1)).startOf('month').toDate();
    let ultimoDia = moment(new Date(y, m + 1, 0)).endOf('month').toDate();
    return {primeiroDia, ultimoDia};
  }
  verificarCampoInvalido(
      formGroup: UntypedFormGroup, campo: string, validador?: string): boolean {
    if (validador) {
      return (
          this.formControl(campo, formGroup)?.hasError(validador) &&
          this.formControl(campo, formGroup)?.touched);
    } else {
      return (
          this.formControl(campo, formGroup)?.errors &&
          this.formControl(campo, formGroup)?.touched);
    }
  }
  buscarMensagemErro(
      formGroup: UntypedFormGroup, campo: string, validador: string,
      global?: boolean): string {
    if (global ? formGroup.hasError(validador) :
                 formGroup.get(campo).hasError(validador)) {
      const errors = global ? formGroup?.errors : formGroup.get(campo)?.errors;
      return errors[validador].message;
    }
    return '';
  }
}
