import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Setor} from '@app/abstraction/domain/entities/cadastros/setor/setor.entity';
import {SetorFiltro} from '@app/abstraction/domain/interfaces/cadastros/setor/setor-filtro.interface';
import {SetorListagem} from '@app/abstraction/domain/interfaces/cadastros/setor/setor-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SetorService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  adicionar(dados: Setor) {
    const response$ =
        this.client.post<Setor>(API.CADASTRO_SETOR, dados)
            .pipe(map((json: Setor) => (json ? Setor.from(json) : null)));
    return lastValueFrom(response$);
  }
  alterar(dados: Setor, id: string) {
    const response$ =
        this.client.put<Setor>(`${API.CADASTRO_SETOR}/${id}`, dados)
            .pipe(map((json: Setor) => (json ? Setor.from(json) : null)));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.CADASTRO_SETOR}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Setor>(`${API.CADASTRO_SETOR}/${id}`)
            .pipe(map((json: Setor) => (json ? Setor.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: SetorFiltro) {
    const response$ = this.client.get<SetorListagem[]>(
        this.geradorQueryString.gerar(`${API.CADASTRO_SETOR}`, filtro));
    return lastValueFrom(response$);
  }
}
