import '@app/shared/extensions/moment.extension';

import {ImpressoraListagem} from '@app/abstraction/domain/interfaces/cadastros/impressora/impressora-listagem.interface';


export class Impressora {
  id: string;
  descricao: string;
  tipoConexao: number;
  url: string;
  porta: string;
  ativo: boolean;
  empresaId: string;

  static from(json: any = {}): Impressora {
    const impressora = new Impressora();
    impressora.id = json.id;
    impressora.ativo = json.ativo;
    impressora.descricao = json.descricao;
    impressora.tipoConexao = json.tipoConexao;
    impressora.url = json.url;
    impressora.porta = json.porta;
    impressora.empresaId = json.empresaId;
    return impressora;
  }
  toListagem(): ImpressoraListagem {
    return <ImpressoraListagem>{
      id: this.id,
      descricao: this.descricao,
      ativo: this.ativo,
    };
  }
}