import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {SetorService} from '@app/abstraction/data/services/cadastros/setor.service';
import {SetorStore} from '@app/abstraction/data/stores/cadastros/setor.store';
import {Setor} from '@app/abstraction/domain/entities/cadastros/setor/setor.entity';
import {SetorFiltro} from '@app/abstraction/domain/interfaces/cadastros/setor/setor-filtro.interface';

@Injectable({
  providedIn: 'root',
})
export class SetorFacade {
  get setores() {
    return this.store.setores;
  }
  get setores$() {
    return this.store.setores$;
  }
  constructor(
      private service: SetorService,
      private store: SetorStore,
  ) {}
  async buscarSetores(filtro?: SetorFiltro) {
    const setores = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(setores);
    return setores;
  }
  async adicionar(setor: Setor) {
    const novoSetor = await this.service.adicionar(setor);
    if (novoSetor) {
      this.store.adicionar(novoSetor.toListagem());
    }
    return novoSetor;
  }
  async atualizarSituacao(id: string) {
    const setor = this.setores.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !setor.ativo);
    setor.ativo = status;
    return status;
  }
  alterar(setor: Setor, id: string) {
    return this.service.alterar(setor, id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaSetores() {
    this.store.setores = null;
  }
}
