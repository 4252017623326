<ng-container *ngIf="control">
  <div class="p-inputgroup">
    <p-dropdown
      #contentInput
      appendTo="body"
      styleClass=" inputfield w-full"
      [editable]="editable"
      [options]="options"
      [placeholder]="placeholder"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [formControl]="control"
      [emptyMessage]="emptyMessage"
      [emptyFilterMessage]="emptyFilterMessage"
      [readonly]="readonly"
      [showClear]="showClear && !readonly"
      [group]="group"
      [filter]="showFilter"
      [ngClass]="{
        readonly: readonly,
        'ng-invalid ng-dirty': hasErrors
      }"
      (onChange)="aoSelecionarItem($event)"
    >
      <ng-template let-group pTemplate="group" *ngIf="group">
        <strong>{{ group.label }}</strong>
      </ng-template>

      <ng-template let-item pTemplate="item" *ngIf="cortarTexto">
        <span>{{ cortarTextoDropdown(item.texto) }}</span>
      </ng-template>

      <ng-template let-item pTemplate="selectedItem" *ngIf="descricaoSigla">
        <div class="flex justify-content-between">
          <span>
            {{ item?.descricao }}
          </span>
          <span>
            {{ item?.sigla }}
          </span>
        </div>
      </ng-template>

      <ng-template let-item pTemplate="item" *ngIf="descricaoSigla">
        <div class="flex justify-content-between">
          <span>
            {{ item?.descricao }}
          </span>
          <span>
            {{ item?.sigla }}
          </span>
        </div>
      </ng-template>

      <ng-template
        let-corSelecionada
        pTemplate="selectedItem"
        *ngIf="templateCor"
      >
        <div class="flex justify-content-between" *ngIf="corSelecionada">
          <span>
            {{ corSelecionada?.descricao }}
          </span>
          <div
            class="caixa-cor"
            [pTooltip]="corSelecionada?.corCodigo"
            tooltipPosition="top"
            [ngStyle]="{ background: corSelecionada?.corCodigo }"
          ></div>
        </div>
      </ng-template>

      <ng-template let-cor pTemplate="item" *ngIf="templateCor">
        <div class="flex justify-content-between" *ngIf="cor">
          <span>
            {{ cor.descricao }}
          </span>
          <div
            class="caixa-cor"
            [pTooltip]="cor.corCodigo"
            tooltipPosition="top"
            [ngStyle]="{ background: cor.corCodigo }"
          ></div>
        </div>
      </ng-template>
    </p-dropdown>

    <p-button
      *ngIf="showButton"
      type="button"
      styleClass="btn-five"
      [icon]="iconButton"
      (onClick)="onClick()"
      [disabled]="buttonDisabled"
    ></p-button>
  </div>

  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
